<template>
    <v-app id="app-menu">
        <AppBar @toggleDrawer="toggleDrawer" @showLogin="showLogin"/>
        <NavigationDrawer v-bind:toggleDrawer="drawer"/>
        <router-view />
        <FooterComponent />
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppBar from '@/components/Home/AppBar.vue'
import NavigationDrawer from '@/components/Home/NavigationDrawer.vue'
import FooterComponent from '@/components/Home/FooterComponent.vue'

export default {
    name: 'HomePageView',
    components: { NavigationDrawer, AppBar, FooterComponent},
    data () {
        return {
            drawer: false,
            login_dialog: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        toggleDrawer () {
            this.drawer = !this.drawer
        },
        showLogin () {
            this.login_dialog = true
        },
    },
    created () {
        // console.log(this.user)
        if (this.user === null) {
            // this.$router.push('/login')
        }
    }
}
</script>

<style scoped>

</style>
