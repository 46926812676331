import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'

Vue.use(Vuex)
// console.log(process.env.API_HOST)

// TODO: use axios.defaults.baseURL = process.env.API_HOST
// TODO: use axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token
// TODO: use axios.defaults.headers.common['Content-Type'] = 'application/json'
// TODO: use axios.defaults.headers.common['Accept'] = 'application/json'
// TODO: use axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// TODO: Remove dispatch

export default new Vuex.Store({
    state: {
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        auctions: [],
    },
    getters: {
        headersConfig: state => {
            return {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${state.user !== null ? state.user.token : null}`
                }
            }
        },
        user: state => state.user,
        auctions: state => state.auctions,
    },
    mutations: {
        setUser(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        setAuctions(state, auctions) {
            state.auctions = auctions
        },
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user)
        },
        getAuctions({ commit, dispatch, getters }) {
            axios
                // .get('http://localhost/autohome/api-rest.php?function=getAuctions')
                .get(`${process.env.API_HOST}:${process.env.API_PORT}/getAuctions`, getters.headersConfig)
                .then(response => {
                    if (response.data.success) {
                        commit('setAuctions', response.data.auctions)
                    } else if (response.status === 401 || response.status === 403) {
                        dispatch('goToLogin')
                    }
                })
                // .catch(function (error) {
                //     console.log(error)
                //     // dispatch('getAuctions')
                // })
        },
        goToLogin() {
            router.push('/login')
        }
    },

})
