<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    >
        <v-card class="elevation-0 pb-16">
            <v-toolbar
                class="elevation-0"
                >
                <v-btn
                    icon
                    @click="back()"
                    large
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title class="my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Política de cookies</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card class="elevation-0 mx-4" v-for="item in cookies_policy" :key="'pp_'+item.id">
                <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                <v-divider v-if="item.title" class="mx-4"></v-divider>
                <v-card-text class="text-break" v-for="text, idx in item.content" :key="'pp_'+item.id+'_'+idx" style="font-size: 18px;" v-html="text">
                </v-card-text>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'CookiesPolicyComponent',
  props: ['dialog'],
  data () {
    return {
        cookies_policy: [
            {
                id: 0,
                content: [
                    'La página web utiliza cookies para mejorar la experiencia del usuario. Esta página de cookies explica qué son las cookies y cómo las utilizamos.'
                ]
            },
            {
                id: 1,
                title: '¿Qué son las cookies?',
                content: [
                    'Las cookies son pequeños archivos de texto que se almacenan en el navegador del usuario cuando visita casi cualquier página web. Las cookies se utilizan para almacenar información sobre las preferencias del usuario, así como para registrar información detallada sobre el comportamiento del usuario en el sitio web.',
                    'Las cookies no suelen contener información que identifique personalmente al usuario, pero la información personal que almacenamos sobre usted puede estar vinculada a la información almacenada y obtenida de las cookies.'
                ]
            },
            {
                id: 2,
                title: '¿Por qué utilizamos cookies?',
                content: [
                    'Utilizamos cookies para mejorar la experiencia del usuario en nuestro sitio web. Almacenamos información sobre sus preferencias para personalizar nuestro sitio web según sus intereses individuales. En algunos casos, también utilizamos cookies para registrar información detallada sobre el comportamiento del usuario en el sitio web, lo que nos permite mejorar nuestro sitio web y facilitar la navegación del usuario.'
                ]
            },
            {
                id: 3,
                title: 'Tipos de cookies',
                content: [
                    'Las cookies pueden clasificarse en función de su duración, su propósito y su controlador.',
                    'Según la duración, las cookies se pueden clasificar en cookies de sesión, que se eliminan cuando el usuario cierra el navegador, y cookies persistentes, que se almacenan en el terminal del usuario durante un período de tiempo determinado y pueden ser accedidas y utilizadas durante ese período de tiempo.',
                    'Según el propósito, las cookies se pueden clasificar en cookies técnicas, que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que existan en ella, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.',
                    'Según el controlador, las cookies se pueden clasificar en propias y de terceros. Las propias cookies son aquellas que se envían al terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario. Las cookies de terceros son aquellas que se envían al terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a través de las cookies.'
                ]
            },
            {
                id: 4,
                title: '¿Cómo deshabilitar las cookies?',
                content: [
                    'El usuario puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.',
                    'La mayoría de los navegadores aceptan las cookies automáticamente, pero normalmente el usuario puede modificar la configuración de su navegador para bloquear las cookies o para que se le notifique cuando se instala una cookie. También puede consultar la información sobre cómo hacerlo en los siguientes enlaces:',
                    '<b>Chrome</b>: <a>http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a>',
                    '<b>Explorer</b>: <a>http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies</a>',
                    '<b>Firefox</b>: <a>http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>',
                    '<b>Safari</b>: <a>http://support.apple.com/kb/ph5042</a>',
                    '<b>Opera</b>: <a>http://help.opera.com/Windows/10.20/es-ES/cookies.html</a>'
                ]
            },
            {
                id: 5,
                title: '¿Qué ocurre si deshabilito las cookies?',
                content: [
                    'Si deshabilita las cookies, es posible que algunas páginas de nuestra web no funcionen correctamente.'
                ]
            },
            {
                id: 6,
                title: 'Cookies propias',
                content: [
                    'Las cookies no utilizadas en la página web se describen a continuación:',
                    '<b>Nombre</b>: PHPSESSID',
                    '<b>Finalidad</b>: Esta cookie es una cookie de sesión que se utiliza para almacenar información sobre las preferencias del usuario y otras informaciones que se proporcionan mientras navega por las páginas web del sitio web.',
                    '<b>Duración</b>: Sesión',
                    '<b>Nombre</b>: _ga',
                    '<b>Finalidad</b>: Esta cookie se utiliza para distinguir a los usuarios.',
                    '<b>Duración</b>: 2 años',
                    '<b>Nombre</b>: _gid',
                    '<b>Finalidad</b>: Esta cookie se utiliza para distinguir a los usuarios.',
                    '<b>Duración</b>: Sesión',
                    '<b>Nombre</b>: _gat',
                    '<b>Finalidad</b>: Esta cookie se utiliza para limitar el porcentaje de solicitudes.',
                    '<b>Duración</b>: Sesión'
                ]
            },
            {
                id: 7,
                title: 'Cookies de terceros',
                content: [
                    'No utilizamos ninguna de las siguientes cookies de terceros:',
                    '<b>Nombre</b>: _ga',
                    '<b>Finalidad</b>: Esta cookie se utiliza para distinguir a los usuarios.',
                    '<b>Duración</b>: 2 años',
                    '<b>Nombre</b>: _gid',
                    '<b>Finalidad</b>: Esta cookie se utiliza para distinguir a los usuarios.',
                    '<b>Duración</b>: Sesión',
                    '<b>Nombre</b>: _gat',
                    '<b>Finalidad</b>: Esta cookie se utiliza para limitar el porcentaje de solicitudes.',
                    '<b>Duración</b>: Sesión'
                ]
            },
            {
                id: 8,
                title: 'Cambios en la política de cookies',
                content: [
                    'Esta página web puede cambiar la presente política de cookies en función de exigencias legislativas, reglamentarias o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos (AEPD). En este caso, se informará a los usuarios en esta página.'
                ]
            },
            {
                id: 9,
                title: 'Contacto',
                content: [
                    'Para cualquier consulta o aclaración sobre la presente política de cookies, puede contactar relleando el formulario de contacto.'
                ]
            },
            {
                id: 10,
                title: 'Aceptación de la política de cookies',
                content: [
                    'El usuario acepta expresamente la presente política de cookies al utilizar la página web, sin perjuicio de que pueda revocar su consentimiento en cualquier momento.'
                ]
            }
        ]
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
