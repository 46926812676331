<template>
    <v-container class="ma-0 pa-0">
        <v-card
            :loading="loading"
            class="mx-auto pa-2"
        >
            <!-- TODO: Que es esto? -->
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>



            <v-card-title class="mt-16 font-weight-black mb-n3"><h3>Perfil</h3></v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card class="elevation-0">
                <v-row>
                    <v-col cols="3" class="py-7 px-6">
                        <v-avatar size="65" color="teal lighten-4" style="font-size: 21px;">JR</v-avatar>
                    </v-col>
                    <v-col cols="9">
                        <v-card-title class="font-weight-black mb-n3 text-break"><h3>{{ profile.name }} {{ profile.first_name }}</h3></v-card-title>
                        <v-card-subtitle class="mt-1 grey--text text--darken-2" style="font-size: 16px;">{{ profile.phone }}</v-card-subtitle>
                    </v-col>
                </v-row>
            </v-card>

            <v-card-title class="pt-1">Nombre</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.name"
                    label="Nombre"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                ></v-text-field>
                <v-text-field
                    v-model="profile.first_name"
                    label="Apellidos"
                    outlined
                    color="primary"
                    hide-details
                ></v-text-field>
            </v-card-text>

            <v-card-title class="pt-1">Email</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.email"
                    label="Email"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                    disabled
                ></v-text-field>
            </v-card-text>

            <v-card-title class="pt-1">Teléfono</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.phone"
                    label="Móvil"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                ></v-text-field>
            </v-card-text>



            <v-card-title class="font-weight-black"><h3>Formas de pago</h3></v-card-title>
            <!-- <v-divider class="mx-4"></v-divider> -->



            <v-card-text>
                <v-card class="elevation-0 secondary pt-1 pb-4 px-2 ma-4 my-2" dark >
                    <v-card-subtitle>Tarjeta de crédito</v-card-subtitle>
                    <v-card-title style="font-size: 21px;">**** **** **** 1929</v-card-title>
                    <v-card-subtitle class="d-flex row px-7">Jorge García<v-spacer></v-spacer><v-chip small label class="px-2 py-1" light><v-img max-width="35px" src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"></v-img></v-chip></v-card-subtitle>
                </v-card>
                <v-btn 
                block 
                class="elevation-0 mt-4" 
                color="secondary" 
                outlined
                @click="payment_dialog = true"
                ><v-icon left>mdi-credit-card-outline</v-icon>Añadir método de pago</v-btn>
                <v-dialog 
                v-model="payment_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-toolbar
                            class="elevation-0"
                            >
                            <v-btn
                                icon
                                @click="payment_dialog = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="my-6 font-weight-black text-break" style="font-size: 35px; line-height: 45px;">Añadir método de pago</v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-list>
                            <!-- <v-subheader>REPORTS</v-subheader> -->
                            <v-list-item-group
                                color="primary"
                                class="mx-2"
                            >
                                <v-list-item class="py-3">
                                    <v-list-item-avatar class="grey lighten-3" size="45">
                                        <v-img max-width="30px" src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" contain></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Tarjeta de débito/crédito</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item class="py-3">
                                    <v-list-item-avatar class="grey lighten-3" size="45">
                                        <v-img max-width="30px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/527px-PayPal_logo.svg.png" contain></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>PayPal</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                            </v-list>
                    </v-card>
                </v-dialog>
            </v-card-text>

            

            <v-card-actions class="pa-4">
                <!-- <v-btn text>Cancelar</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0 px-4">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
// import GadgetDialog from './GadgetDialog.vue'

export default {
  name: 'CustomerConfigView',
  components: {
    // GadgetDialog
  },
  data () {
    return {
        payment_dialog: false,
        profile: {
            id: null,
            name: 'Jorge',
            first_name: 'García Simulado',
            email: 'admin@bidapp.vip',
            phone: '+34679874636',
        }
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {
    // ...mapActions(['getGadgets', 'executeAction', 'getSites', 'getTypes']),
    // newGadget (siteId) {
    //     this.editedSiteId = siteId
    //     this.showGadgetDialog = true
    // },
    // toggleGadget (gadget) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: !gadget.state,
    //         action_id: !gadget.state ? gadget.state_on_action_id : gadget.state_off_action_id
    //     }
    //     this.executeAction(dataAction)
    //     .then(response => {
    //         if (response.data.success) {
    //             this.getGadgets()
    //             this.close()
    //         } else {
    //             console.log(response.data.message)
    //         }
    //     })
    //     .finally(() => {
    //         gadget.loading = false
    //     })
    // },
    // setGadgetState (gadget, state) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: state,
    //         action_id: state ? gadget.state_on_action_id : gadget.state_off_action_id,
    //     }
    //     if (state !== gadget.state) {
    //         this.executeAction(dataAction)
    //         .then(response => {
    //             if (response.data.success) {
    //                 this.getGadgets()
    //                 this.close()
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .finally(() => {
    //             gadget.loading = false
    //         })
    //     } else {
    //         gadget.loading = false
    //     }
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
