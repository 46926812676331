<template>
    <v-app-bar
        color="blue-grey darken-3"
        dark
        app
        clipped-left
    >
        
        <v-img :src="require('@/assets/logo-white.png')" height="55" max-width="110" contain></v-img>
        <!-- <v-toolbar-title class="ml-5 d-none d-sm-block"><span class="kozuka-b">TIM</span><span class="kozuka-el">BER</span></v-toolbar-title> -->

        <v-spacer></v-spacer>
        <!-- <v-toolbar-title class="mr-5 d-none d-sm-block"><span class="kozuka-el">AO</span></v-toolbar-title> -->
        <!-- <v-hover v-slot="{ hover }">
            <a>
                <v-avatar light :class="hover ? 'red' : 'grey lighten-2'">
                    <span  v-if="!hover" style="color: #666;">JR</span>
                    <v-icon v-if="hover">mdi-power-standby</v-icon>
                </v-avatar>
            </a>
        </v-hover> -->
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')">
        </v-app-bar-nav-icon>
    </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
