<template>
    <v-container class="ma-0 pa-0">
        <v-card
            :loading="loading"
            class="mx-auto"
        >
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>

            <!-- <v-img
            height="250"
            src="https://www.mejoresdiscotecasmadrid.es/wp-content/uploads/2019/05/Discoteca-teatro-barcelo-listas-reservados-madrid.jpg"
            ></v-img> -->
            
            <v-card-title class="mt-16 font-weight-black"><h3>Subastas publicadas</h3></v-card-title>

            <!-- <v-divider class="mx-4"></v-divider> -->
            <v-card-text>
                <v-card outlined>
                    <v-card-title class="pt-2 text-center">Balance total</v-card-title>
                    <v-card-text class="text-center my-0 pb-8">
                        <h1 class="secondary--text" style="font-size: 31px;">564,90€</h1>
                    </v-card-text>
                </v-card>
            </v-card-text>
            
            <!-- <v-divider class="mx-4"></v-divider> -->
            <v-card-title>Resumen</v-card-title>
            <v-card-text>
                <v-card outlined>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" class="d-flex justify-center">
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-chip label class="black--text teal lighten-5" style="font-size: 15px;">Abiertas</v-chip>
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center mt-0 pt-0">
                                        <span class="black--text" style="font-size: 20px;"><b>5</b></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-center">
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-chip label class="black--text grey lighten-5" style="font-size: 15px;">Cerradas</v-chip>
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center mt-0 pt-0">
                                        <span class="black--text" style="font-size: 20px;"><b>17</b></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-center">
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-chip label class="black--text orange lighten-5" style="font-size: 15px;">Sin publicar</v-chip>
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center mt-0 pt-0">
                                        <span class="black--text" style="font-size: 20px;"><b>2</b></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>


            <v-card-title class="pb-2">Subastas</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-card outlined class="d-flex flex-row align-start my-2" v-for="item in bids" :key="item">
                        <v-img 
                        :src="item.img"
                        max-width="100"
                        min-height="100"
                        class="mr-2"
                        >
                        </v-img>
                        <v-row>
                            <v-col cols="12" class="ma-0 pa-0 mt-n1">
                                <v-card-title class="pb-2 font-weight-black" style="font-size:17px;">{{ item.title }}</v-card-title>
                                <v-card-subtitle class="pb-0" style="font-size:12px;">{{ item.subtitle }}</v-card-subtitle>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0">
                                <!-- <v-card-subtitle  class="my-0 py-0"  style="font-size:13px;">547 pujas</v-card-subtitle> -->
                                <v-chip label x-small class="ml-4" color="grey lighten-4">{{ item.date }}</v-chip>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="7" class="py-0">
                                            <span class="secondary--text" style="font-size: 16px;"><b>{{ item.final_price }}€</b></span>
                                            <span class="ml-1" style="font-size: 11px;"><b>+{{ item.diff }}€</b></span>
                                        </v-col>
                                        <v-col cols="5" class="ma-0 pa-0 text-right">
                                            <v-chip 
                                            label
                                            small 
                                            class="mr-5 black--text mt-n3"
                                            :class="item.state === 'active' ? 'teal lighten-4' : item.state === 'closed' ? 'grey lighten-5' : 'orange lighten-5'"
                                            >
                                                {{ states.find(s => s.value === item.state)?.name }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>

                        </v-row>
                </v-card>
            </v-card-text>

            
        </v-card>
    </v-container>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
// import GadgetDialog from './GadgetDialog.vue'

export default {
  name: 'PublishedBidsView',
  components: {
    // GadgetDialog
  },
  data () {
    return {
        states: [
            {
                value: 'active',
                name: 'Activa'
            },
            {
                value: 'closed',
                name: 'Cerrada'
            },
            {
                value: 'unpublished',
                name: 'Sin publicar'
            }
        ],
        bids: [
            {
                img: 'https://onlyibizaboatparty.com/templates/yootheme/cache/mejores-discotecas-de-ibiza-1043x605-ba9d360f.jpeg',
                title: 'Aplilaco',
                subtitle: 'Peor reservado',
                final_price: '172,00',
                date: '2023-02-12',
                diff: '35,00',
                state: 'active',
            },
            {
                img: 'https://onlyibizaboatparty.com/templates/yootheme/cache/mejores-discotecas-de-ibiza-1043x605-ba9d360f.jpeg',
                title: 'Aplilaco',
                subtitle: 'Peor reservado',
                final_price: '172,00',
                date: '2023-02-13',
                diff: '35,00',
                state: 'active',
            },
            {
                img: 'https://image.ondacero.es/clipping/cmsimages01/2021/10/22/EC46FE73-78DB-430B-8F71-991565170FC7/98.jpg',
                title: 'Disco Brinco',
                subtitle: 'Reservado 2',
                final_price: '325,00',
                date: '2022-12-09',
                diff: '65,00',
                state: 'closed',
            },
            {
                img: 'https://www.japonalternativo.com/wp-content/uploads/2022/08/mejores-fiestas-en-tokio.jpg',
                title: 'Pub Licores',
                subtitle: 'Resrvado Top',
                final_price: '285,00',
                date: '2022-11-04',
                diff: '45,00',
                state: 'unpublished',
            },
            {
                img: 'https://apil.es/wp-content/uploads/2019/07/Iluminacion-led-en-discotecas-y-pubs.jpg',
                title: 'Disco Tema',
                subtitle: 'Mesa 1',
                final_price: '423,00',
                date: '2022-11-01',
                diff: '123,00',
                state: 'active',
            },
        ]
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {
    // ...mapActions(['getGadgets', 'executeAction', 'getSites', 'getTypes']),
    // newGadget (siteId) {
    //     this.editedSiteId = siteId
    //     this.showGadgetDialog = true
    // },
    // toggleGadget (gadget) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: !gadget.state,
    //         action_id: !gadget.state ? gadget.state_on_action_id : gadget.state_off_action_id
    //     }
    //     this.executeAction(dataAction)
    //     .then(response => {
    //         if (response.data.success) {
    //             this.getGadgets()
    //             this.close()
    //         } else {
    //             console.log(response.data.message)
    //         }
    //     })
    //     .finally(() => {
    //         gadget.loading = false
    //     })
    // },
    // setGadgetState (gadget, state) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: state,
    //         action_id: state ? gadget.state_on_action_id : gadget.state_off_action_id,
    //     }
    //     if (state !== gadget.state) {
    //         this.executeAction(dataAction)
    //         .then(response => {
    //             if (response.data.success) {
    //                 this.getGadgets()
    //                 this.close()
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .finally(() => {
    //             gadget.loading = false
    //         })
    //     } else {
    //         gadget.loading = false
    //     }
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
