<template>
    <v-container class="ma-0 pa-0">
        <v-card
            :loading="loading"
            class="mx-auto"
        >
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>

            <v-img
            height="250"
            src="https://madriddiferente.com/wp-content/uploads/2022/04/Claptone0482-1-770x466.jpg"
            ></v-img>

            <v-card-title class="font-weight-black" style="font-size: 23px;">Disco Brinco</v-card-title>

            <v-card-text>

                <div class="mb-2 mt-n2 text-subtitle-1">
                    <v-chip label x-small class="mr-1 px-2 py-1">€€</v-chip>
                    <v-chip label x-small class="mr-1 px-2 py-1">centro</v-chip>
                    <v-chip label x-small class="mr-1 px-2 py-1">discoteca</v-chip>
                    <v-chip label x-small class="mr-1 px-2 py-1">tecno</v-chip>
                    <v-chip label x-small class="mr-1 px-2 py-1">reggaetton</v-chip>
                </div>

                <div>La Disco Brinco ofrece diversión, buena música y un ambiente inigualable a sus asistentes. Durante tan solo tres días a la semana: jueves, viernes y sábado, la Disco Brinco brinda las mejores sesiones de música, desde las doce de la noche hasta altas horas de la madrugada.</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-sheet
                        max-width="100%"
                    >
                        <v-slide-group
                        v-model="model"
                        class="pa-0"
                        mandatory
                        :show-arrows="false"
                        center-active
                        >
                            <v-slide-item
                                v-for="n in 15"
                                :key="n"
                                v-slot="{ active, toggle }"
                            >
                                <v-badge 
                                    overlap 
                                    bordered 
                                    class="mt-2 ml-1"
                                    :value="n == 5 ? false : false"
                                    color="warning"
                                >
                                    <v-card
                                    :color="active ? 'primary' : n % 2 == 0 ? 'grey' : 'grey lighten-3'"
                                    class="ma-1"
                                    height="120"
                                    width="80"
                                    @click="toggle"
                                    :disabled="n % 2 == 0"
                                    :class="n % 2 == 0 ? 'elevation-0' : ''"
                                    >
                                        <!-- <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-scale-transition>
                                                <v-icon
                                                    v-if="active"
                                                    color="white"
                                                    size="48"
                                                    v-text="'mdi-close-circle-outline'"
                                                ></v-icon>
                                            </v-scale-transition>
                                        </v-row> -->
                                        <v-card-text class="px-0">
                                            <v-row :class="active ? 'grey--text text--lighten-3' : ''">
                                                <v-col cols="12" class="d-flex justify-center py-2 pb-0">
                                                    <span style="font-size:14px;" class="mt-1">LUN</span>
                                                    <!-- <v-chip label style="font-size:15px;" small class="mt-1 py-2 px-2" color="lighten">L</v-chip> -->
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center py-3">
                                                    <span style="font-size:35px;">{{ n }}</span>
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center py-0">
                                                    <span style="font-size:9px;">DICIEMBRE</span>
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center my-n5" v-if="n % 2 == 0">
                                                    <span style="font-size:9px;" class="white--text">SIN SUBASTAS</span>
                                                    <!-- <v-chip label style="font-size:15px;" small class="mt-1 py-2 px-2">L</v-chip> -->
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-badge>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-row>
            </v-card-text>

            <v-divider class="mx-4 mt-2"></v-divider>

            <v-card-title class="font-weight-black">Pujar</v-card-title>

            <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-col cols="5">
                        <span>Puja actual:</span>
                        <v-icon @click="dialog_info_auction = true" right>mdi-information</v-icon>
                        <v-dialog v-model="dialog_info_auction">
                            <v-alert
                                type="info"
                                color="primary"
                                colored-border
                                border="left"
                                elevation="1"
                                >
                                Este campo indica el precio a superar de la subasta. En el caso de subastas de múltiples unidades, el valor corresponderá al precio de la puja más baja que obtendría una unidad del producto subastado. Por ejemplo, con 5 unidades subastadas, este campo indicaría la quinta puja más baja.
                            </v-alert>
                        </v-dialog>
                    </v-col>
                    <v-col cols="7">
                        <span label outlined color="secondary"><h2>356,23€<v-chip label x-small class="ml-3">39 pujas</v-chip></h2></span>
                    </v-col>
                </v-row>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-col cols="5">
                        <span>Tiempo restante:</span>
                    </v-col>
                    <v-col cols="7">
                        <span label outlined color="secondary"><h4>1d 22h<v-chip label x-small class="ml-3">Sábado, 20:54</v-chip></h4></span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="px-2">
                    <!-- <v-col cols="7">
                        <v-sparkline
                            :value="value"
                            color="primary"
                            line-width="3"
                            padding="10"
                            :smooth="100"
                            stroke-linecap="round"
                        ></v-sparkline>
                    </v-col> -->
                    <v-col cols="6" class="px-2">
                        <v-text-field
                            label="Importe"
                            outlined
                            dense
                            color="primary"
                            class="mr-2"
                            suffix="€"
                            hide-details
                        ></v-text-field>
                        <span style="font-size: 11px;" class="ml-2">Introduce 370,02€ o más</span>
                    </v-col>
                    <v-col cols="6">
                        <v-btn 
                        class="mr-2 elevation-0"
                        block
                        rounded
                        color="primary"
                        >Pujar</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-divider class="mx-4"></v-divider> -->
            <v-card-text class="d-flex justify-center pt-0">
                <!-- <v-chip-group
                    v-model="selection"
                    active-class="deep-purple accent-4 white--text"
                    column
                > -->
                    <v-btn class="mr-2 elevation-1 mx-2">+5€</v-btn>
                    <v-btn class="mr-2 elevation-1 mr-2">+20€</v-btn>
                    <v-btn class="mr-2 elevation-1 mr-2">+50€</v-btn>
                    <v-btn class="mr-2 elevation-1 mr-2">+100€</v-btn>
                <!-- </v-chip-group> -->
            </v-card-text>

            <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-col cols="3">
                        <span>Pagos:</span>
                    </v-col>
                    <v-col cols="9">
                        <v-chip small label class="px-2 py-1 mr-2"><v-img max-width="40px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/527px-PayPal_logo.svg.png"></v-img></v-chip>
                        <v-chip small label class="px-2 py-1 mr-2"><v-img max-width="35px" src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"></v-img></v-chip>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
// import GadgetDialog from './GadgetDialog.vue'

export default {
  name: 'BidView',
  components: {
    // GadgetDialog
  },
  data () {
    return {
        showGadgetDialog: false,
        dialog_info_auction: false,
        editedSiteId: null,
        selection: [],
        model: null,
        labels: [
            '12am',
            '3am',
            '6am',
            '9am',
            '12pm',
            '3pm',
            '6pm',
            '9pm',
        ],
        value: [
            200,
            675,
            410,
            390,
            310,
            460,
            250,
            240,
        ],
        // sites: [
        //     {
        //         id: 1,
        //         name: "Salón",
        //     },
        //     {
        //         id: 2,
        //         name: "Habitación",
        //     },
        // ],
        // gadgets: [
        //     {
        //         id: 1,
        //         name: "Bombilla 1",
        //         site_id: 2,
        //         state: 1,
        //         state_on_name: "Encendida",
        //         state_off_name: "Apagada",
        //         is_pulse_type: false,
        //         loading: false,
        //     },
        //     {
        //         id: 2,
        //         name: "Bombilla 2",
        //         site_id: 1,
        //         state: 0,
        //         state_on_name: "Encendida",
        //         state_off_name: "Apagada",
        //         is_pulse_type: false,
        //         loading: false,
        //     },
        //     {
        //         id: 3,
        //         name: "Bombilla 4",
        //         site_id: 2,
        //         state: 0,
        //         state_on_name: "Encendida",
        //         state_off_name: "Apagada",
        //         is_pulse_type: false,
        //         loading: false,
        //     },
        //     {
        //         id: 4,
        //         name: "Bombilla 3",
        //         site_id: 2,
        //         state: 1,
        //         state_on_name: "Luz",
        //         state_off_name: "Apagada",
        //         is_pulse_type: false,
        //         loading: false,
        //     },
        // ]
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {
    // ...mapActions(['getGadgets', 'executeAction', 'getSites', 'getTypes']),
    // newGadget (siteId) {
    //     this.editedSiteId = siteId
    //     this.showGadgetDialog = true
    // },
    // toggleGadget (gadget) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: !gadget.state,
    //         action_id: !gadget.state ? gadget.state_on_action_id : gadget.state_off_action_id
    //     }
    //     this.executeAction(dataAction)
    //     .then(response => {
    //         if (response.data.success) {
    //             this.getGadgets()
    //             this.close()
    //         } else {
    //             console.log(response.data.message)
    //         }
    //     })
    //     .finally(() => {
    //         gadget.loading = false
    //     })
    // },
    // setGadgetState (gadget, state) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: state,
    //         action_id: state ? gadget.state_on_action_id : gadget.state_off_action_id,
    //     }
    //     if (state !== gadget.state) {
    //         this.executeAction(dataAction)
    //         .then(response => {
    //             if (response.data.success) {
    //                 this.getGadgets()
    //                 this.close()
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .finally(() => {
    //             gadget.loading = false
    //         })
    //     } else {
    //         gadget.loading = false
    //     }
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
