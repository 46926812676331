<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    >
        <v-card class="elevation-0">
            <v-toolbar
                class="elevation-0"
                >
                <v-btn
                    icon
                    @click="back()"
                    large
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title class="my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Preguntas frecuentes</v-card-title>
            
            <!-- <v-card-title class="font-weight-black text-break secondary--text" style="font-size: 24px;">Clientes</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-list>
                <v-list-group
                    color="primary"
                    class="mx-2"
                    v-model="item.active"
                    v-for="item in frequent_questions.customer" 
                    :key="item.id"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <span>{{ item.question }}</span>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="py-3" >
                        <v-list-item-content>
                            <span class="font-weight-regular">{{ item.answer }}</span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list> -->
            <!-- <v-card-title class="font-weight-black text-break secondary--text" style="font-size: 24px;">Negocios</v-card-title> -->
            <v-divider class="mx-4"></v-divider>
            <v-list>
                <v-list-group
                    color="primary"
                    class="mx-2"
                    v-model="item.active"
                    v-for="item in frequent_questions.business" 
                    :key="item.id"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <span>{{ item.question }}</span>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="py-3" >
                        <v-list-item-content>
                            <span class="font-weight-regular">{{ item.answer }}</span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
  name: 'FrequentQuestionsComponent',
  props: ['dialog'],
  data () {
    return {
        frequent_questions: {
            customer: [
                {
                    id: 1,
                    question: '¿Cómo puedo realizar una reserva de un reservado de discoteca en su sitio web?',
                    answer: 'Para realizar una reserva de un reservado de discoteca en nuestro sitio web, primero deberás registrarte en nuestro sitio y luego buscar las subastas disponibles. Una vez que encuentres una subasta que te interese, deberás realizar una oferta en el precio de reserva del reservado. Si tu oferta es la más alta al final de la subasta, se te notificará y podrás completar la reserva.',
                },
                {
                    id: 2,
                    question: '¿Qué está incluido en la reserva de un reservado de discoteca?',
                    answer: 'Las reservas de reservados de discoteca varían dependiendo del lugar y del paquete que hayas adquirido. Generalmente, las reservas incluyen una mesa en un área reservada de la discoteca, servicio de botella de alcohol y/o refrescos y entrada gratuita para un número determinado de personas. Consulta los detalles específicos de cada subasta antes de realizar una oferta.'
                },
                {
                    id: 3,
                    question: '¿Cómo puedo pagar por mi reserva?',
                    answer: 'Aceptamos varios métodos de pago, incluyendo tarjetas de crédito y débito, PayPal y otros servicios de pago en línea. Los detalles del pago se proporcionarán una vez que completes tu reserva exitosamente.'
                },
                {
                    id: 4,
                    question: '¿Puedo cancelar o modificar mi reserva?',
                    answer: 'Las cancelaciones y modificaciones de reservas están sujetas a nuestras políticas de cancelación. Por favor, revisa las políticas de cancelación para cada subasta antes de realizar una oferta. En general, las reservas son no reembolsables y no se pueden modificar una vez que se ha completado la subasta y la reserva se ha confirmado.'
                },
                {
                    id: 5,
                    question: '¿Puedo transferir mi reserva a otra persona?',
                    answer: 'La transferencia de reservas varía dependiendo de la subasta y las políticas del lugar. Por favor, consulta las políticas de transferencia de cada subasta antes de realizar una oferta.'
                },
                {
                    id: 6,
                    question: '¿Qué sucede si llego tarde a mi reserva?',
                    answer: 'Si llegas tarde a tu reserva, debes comunicarte con nosotros para informarnos del retraso. Si llegas demasiado tarde, es posible que se cancele tu reserva y no se te reembolsará.'
                },
                {
                    id: 7,
                    question: '¿Puedo reservar un reservado de discoteca para una ocasión especial o evento privado?',
                    answer: 'Sí, es posible reservar un reservado de discoteca para una ocasión especial o evento privado. Comunícate con nosotros para obtener más detalles sobre las opciones disponibles y los precios.'
                },
            ],
            business: [
                {
                    id: 1,
                    question: '¿Cómo puedo publicar una subasta de reservado de discoteca en su sitio web?',
                    answer: 'Para publicar una subasta de reservado de discoteca en nuestro sitio web, primero deberás registrarte en nuestro sitio y luego completar el formulario de publicación de subasta. Una vez que hayas completado el formulario, nos pondremos en contacto contigo para confirmar la información y ayudarte a publicar tu subasta.'
                },
                {
                    id: 2,
                    question: '¿Qué está incluido en la publicación de una subasta de reservado de discoteca?',
                    answer: 'Las subastas de reservados de discoteca varían dependiendo del lugar y del paquete que hayas elegido. Generalmente, las subastas incluyen una mesa en un área reservada de la discoteca, servicio de botella de alcohol y/o refrescos y entrada gratuita para un número determinado de personas. Consulta los detalles específicos de cada subasta antes de publicarla.'
                },
                {
                    id: 3,
                    question: '¿Cómo puedo pagar por la publicación de una subasta de reservado de discoteca?',
                    answer: 'Aceptamos varios métodos de pago, incluyendo tarjetas de crédito y débito, PayPal y otros servicios de pago en línea. Los detalles del pago se proporcionarán una vez que completes tu reserva exitosamente.'
                },
                {
                    id: 4,
                    question: '¿Puedo cancelar o modificar la publicación de una subasta de reservado de discoteca?',
                    answer: 'Las cancelaciones y modificaciones de subastas están sujetas a nuestras políticas de cancelación. Por favor, revisa las políticas de cancelación para cada subasta antes de publicarla. En general, las subastas son no reembolsables y no se pueden modificar una vez que se ha completado la publicación.'
                },
                {
                    id: 5,
                    question: '¿Qué sucede si mi subasta no recibe ofertas?',
                    answer: 'Si tu subasta no recibe ofertas, se cancelará automáticamente y no se te cobrará.'
                },
                {
                    id: 6,
                    question: '¿Puedo publicar una subasta de reservado de discoteca para una ocasión especial o evento privado?',
                    answer: 'Sí, es posible publicar una subasta de reservado de discoteca para una ocasión especial o evento privado. Comunícate con nosotros para obtener más detalles sobre las opciones disponibles y los precios.'
                },
            ],
        }
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
