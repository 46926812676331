<template>
  <div id="app">
    <router-view />
    <!-- <router-view name="login" /> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
/* @import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css? family=Quicksand:300,400,700&display=swap'); */
@font-face {
    font-family: "PlusJakartaSans";
    src: url("@/assets/fonts/PlusJakartaSans.ttf");
}

@font-face {
    font-family: "Quicksand";
    src: url("@/assets/fonts/Quicksand.ttf");
}

@font-face {
    font-family: "Gotham";
    /* src: url("@/assets/fonts/GothamLight.ttf"); */
    src: url("@/assets/fonts/GothamBook.ttf");
}
@font-face {
    font-family: "Gotham";
    src: url("@/assets/fonts/GothamBold.ttf");
    font-weight: bold;
}
/* @font-face {
    font-family: "Gotham";
    src: url("fonts/DejaVuSans-Oblique.ttf");
    font-style: italic, oblique;
}
@font-face {
    font-family: "Gotham";
    src: url("fonts/DejaVuSans-BoldOblique.ttf");
    font-weight: bold;
    font-style: italic, oblique;
} */

#app {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* margin-top: 56px; */
}

.v-application {
  font-family: 'PlusJakartaSans', 'Quicksand', 'Gotham', 'Avenir', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}

.v-btn {
  text-transform: capitalize !important;
  font-weight: 500 !important;
}

#app .translucient-white {
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.8);
}

#app .translucient-teal {
  backdrop-filter: blur(20px);
  background: rgba(158, 250, 241, 0.7);
}

#app .translucient-dark {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.3);
}
</style>