<template>
    <v-container>
        <v-card
            :loading="loading"
            class="mx-auto elevation-0"
            tile
            
        >
            <!-- TODO: Que es esto? -->
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>



            <v-card-title class="mt-16 font-weight-black mb-n3"><h3>Perfil</h3></v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card class="elevation-0">
                <v-row>
                    <v-col cols="3" class="py-7 px-6">
                        <v-avatar size="65" color="teal lighten-4" style="font-size: 21px;">JR</v-avatar>
                    </v-col>
                    <v-col cols="9">
                        <v-card-title class="font-weight-black mb-n3 text-break"><h3>{{ profile.name }} {{ profile.first_name }}</h3></v-card-title>
                        <v-card-subtitle class="mt-1 grey--text text--darken-2" style="font-size: 16px;">{{ profile.phone }}</v-card-subtitle>
                    </v-col>
                </v-row>
            </v-card>

            <v-card-title class="pt-1">Nombre</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.name"
                    label="Nombre"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                ></v-text-field>
                <v-text-field
                    v-model="profile.first_name"
                    label="Apellidos"
                    outlined
                    color="primary"
                    hide-details
                ></v-text-field>
            </v-card-text>

            <v-card-title class="pt-1">Email</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.email"
                    label="Email"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                    disabled
                ></v-text-field>
            </v-card-text>

            <v-card-title class="pt-1">Teléfono</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="profile.phone"
                    label="Móvil"
                    outlined
                    color="primary"
                    hide-details
                    class="mb-4"
                ></v-text-field>
            </v-card-text>



            <v-card-title class="font-weight-black"><h3>Formas de cobro</h3></v-card-title>
            <!-- <v-divider class="mx-4"></v-divider> -->



            <v-card-text class="mb-4">
                <v-autocomplete
                v-model="profile.payment_method_id"
                :items="profile.payments"
                item-text="name"
                item-value="id"
                label="Método de cobro predeterminado"
                outlined
                color="primary"
                hide-details
                class="mb-4"
                :disabled="profile.payments.length === 0"
                ></v-autocomplete>
                <v-btn 
                block 
                class="elevation-0 mt-4" 
                color="secondary" 
                outlined
                @click="payment_dialog = true"
                ><v-icon left>mdi-credit-card-outline</v-icon>Editar formas de cobro</v-btn>

                <v-dialog 
                v-model="payment_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-toolbar
                            class="elevation-0"
                            >
                            <v-btn
                                icon
                                @click="payment_dialog = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="my-6 font-weight-black text-break" style="font-size: 35px; line-height: 45px;">Añadir método de cobro</v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-list>
                            <v-list-group
                                color="primary"
                                class="mx-2"
                                v-for="method in profile.payments" 
                                :key="'pm'+method.id"
                                v-model="method.active"
                                :append-icon="method.active ? 'mdi-close' : ''"
                            >
                                <template v-slot:activator>
                                    <v-list-item class="py-3">
                                        <v-list-item-avatar class="grey lighten-3" size="45">
                                            <v-icon>mdi-credit-card</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ method.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-list-item class="py-3" >
                                    <v-list-item-content>
                                        <v-text-field
                                        v-model="method.name"
                                        label="Nombre"
                                        outlined
                                        color="primary"
                                        hide-details
                                        class="mb-4"
                                        ></v-text-field>
                                        <v-text-field
                                        v-model="method.iban"
                                        label="IBAN"
                                        outlined
                                        color="primary"
                                        hide-details
                                        class="mb-4"
                                        ></v-text-field>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn
                                                block
                                                class="elevation-0 mt-4"
                                                color="red"
                                                outlined
                                                @click="deletePaymentMethod(method.id)"
                                                >
                                                    <!-- <v-icon left>mdi-close</v-icon> -->
                                                    Eliminar
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                block
                                                class="elevation-0 mt-4"
                                                color="primary"
                                                @click="method.active = false"
                                                >
                                                    <!-- <v-icon left>mdi-save</v-icon> -->
                                                    Guardar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item-group
                                color="primary"
                                class="mx-2 mt-6"
                            >
                                <v-list-item class="py-2 teal lighten-5" @click="addPaymentMethod">
                                    <v-list-item-avatar class="white" size="45">
                                        <v-icon color="primary">mdi-plus</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text">Añadir forma de cobro</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-dialog>
            </v-card-text>


            <v-card-title class="font-weight-black"><h3>Facturación</h3></v-card-title>
            <v-card class="elevation-0">
                <v-list-item-group
                color="primary"
                class="mx-2"
                v-for="(invoice, idx) in profile.invoices"
                :key="'invoice'+invoice.id"
                >
                    <v-divider v-if="idx === 0"></v-divider>
                    <v-list-item class="py-1" @click="addPaymentMethod">
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text">{{ invoice.date }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar class="grey lighten-3" size="35">
                            <v-icon dense color="secondary">mdi-download</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list-item-group>
            </v-card>
            

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0 px-4">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
// import GadgetDialog from './GadgetDialog.vue'

export default {
  name: 'BusinessConfigView',
  components: {
    // GadgetDialog
  },
  data () {
    return {
        payment_dialog: false,
        edit_iban: false,
        profile: {
            id: null,
            name: 'Jorge',
            first_name: 'García Simulado',
            email: 'jgarcia@bidapp.vip',
            phone: '+34679874636',
            payment_method_id: 1,
            payments: [
                {
                    id: 1,
                    iban: 'ES1234567890123456789012',
                    name: 'Cuenta BBVA',
                },
                {
                    id: 2,
                    iban: 'ES1234567890123456789012',
                    name: 'Cuenta Santander',
                }
            ],
            invoices: [
                {
                    id: 1,
                    date: '2023-03-01',
                },
                {
                    id: 1,
                    date: '2023-02-01',
                },
                {
                    id: 1,
                    date: '2023-01-01',
                },
            ]
        }
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {
    addPaymentMethod () {
        this.profile.payments.push({
            id: null,
            iban: '',
            name: '',
            active: true,
        })
    },

    deletePaymentMethod (id) {
        this.profile.payments = this.profile.payments.filter(payment => payment.id !== id)
    },
    // ...mapActions(['getGadgets', 'executeAction', 'getSites', 'getTypes']),
    // newGadget (siteId) {
    //     this.editedSiteId = siteId
    //     this.showGadgetDialog = true
    // },
    // toggleGadget (gadget) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: !gadget.state,
    //         action_id: !gadget.state ? gadget.state_on_action_id : gadget.state_off_action_id
    //     }
    //     this.executeAction(dataAction)
    //     .then(response => {
    //         if (response.data.success) {
    //             this.getGadgets()
    //             this.close()
    //         } else {
    //             console.log(response.data.message)
    //         }
    //     })
    //     .finally(() => {
    //         gadget.loading = false
    //     })
    // },
    // setGadgetState (gadget, state) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: state,
    //         action_id: state ? gadget.state_on_action_id : gadget.state_off_action_id,
    //     }
    //     if (state !== gadget.state) {
    //         this.executeAction(dataAction)
    //         .then(response => {
    //             if (response.data.success) {
    //                 this.getGadgets()
    //                 this.close()
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .finally(() => {
    //             gadget.loading = false
    //         })
    //     } else {
    //         gadget.loading = false
    //     }
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
