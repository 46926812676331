<template>
    <v-navigation-drawer v-model="drawer" app class="translucient-teal pt-2" right>
        <v-row class="mb-6">
            <v-col cols="12" class="d-flex justify-end">
                <v-btn fab text small class="mr-2" @click="drawer = !drawer">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-img :src="require('@/assets/logo-black.png')" max-height="40" contain></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- <SiteDialog v-model="showSiteDialog" :home-id="editedHomeId" :site-id="editedSiteId"/> -->
        <!-- <template v-for="home in homes"> -->
            <!-- <v-row class="pa-0 ma-0">
                <v-subheader class="primary--text text--darken-1 font-weight-bold">{{home.name}}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn icon small class="mt-2 mr-3" @click="newSite(home.id)">
                    <v-icon color="primary darken-1">
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-row> -->
            <v-list dense nav class="py-0">
                <v-list-item-group v-model="selectedItem">
                    <template>
                        <v-list-item
                        v-for="item in items"
                        :key="item.title"
                        link
                        @click="item.action"
                        >
                            <template>
                                <v-list-item-icon class="mx-2">
                                    <v-icon left>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="mt-1 font-weight-bold">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-divider class="mt-3"></v-divider>
        <!-- </template> -->
        <!-- <v-row class="pa-3">
            <v-col cols="12">
                <v-btn block small color="primary" @click="showHomeDialog=true">
                    <v-icon left>mdi-plus</v-icon>
                    Añadir hogar
                </v-btn>
                <HomeDialog v-model="showHomeDialog"/>
            </v-col>
        </v-row> -->
    </v-navigation-drawer>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
// import HomeDialog from './HomeDialog.vue'
// import SiteDialog from './SiteDialog.vue'

export default {
    name: 'NavigationDrawer',
    props: ['toggleDrawer'],
    components: {
        // HomeDialog,
        // SiteDialog
    },
    data() {
        return {
            drawer: false,
            showHomeDialog: false,
            editedHomeId: null,
            showSiteDialog: false,
            editedSiteId: null,
            selectedItem: 0,
            items: [
                // {
                //     title: 'Pujar',
                //     icon: 'mdi-view-dashboard',
                //     action: () => {
                //         this.$router.push('/home/dashboard')
                //     }
                // },
                // {
                //     title: 'Publicar subasta',
                //     icon: 'mdi-plus',
                //     action: () => {
                //         this.$router.push('/home/edit-bid')
                //     }
                // },
                {
                    title: 'Mis subastas',
                    icon: 'fa-money-bill-trend-up',
                    path: '/c/my-bids',
                    action: () => {
                        this.$router.push('/c/my-bids')
                    }
                },
                {
                    title: 'Configuración',
                    icon: 'mdi-cog',
                    path: '/c/config',
                    action: () => {
                        this.$router.push('/c/config')
                    }
                },
                {
                    title: 'Centro de ayuda',
                    icon: 'mdi-help-circle',
                    action: () => {
                        this.$router.push('/c/help')
                    }
                },
                {
                    title: 'Cerrar sesión',
                    icon: 'mdi-logout',
                    path: '/join-us',
                    action: () => {
                        this.$router.push('/')
                    }
                },
            ],
        }
    },
    computed: {

    },
    watch: {
        toggleDrawer() {
            this.drawer = !this.drawer
        },
        showSiteDialog(val) {
            if (!val) {
                this.editedHomeId = null
            }
        },
    },
    methods: {

    },
    created() {
        this.selectedItem = this.items.findIndex(item => item.path == this.$route.path)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
