/* eslint-disable indent */
<template>
    <v-app id="app-menu">
        <v-container>
            <v-dialog
                v-model="login_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card class="elevation-2" dark>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon large @click="$router.push('/intro')"> -->
                        <v-btn icon large @click="close">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-title class="mt-1">
                        <v-img max-height="65" contain class="d-flex justify-center " :src="require('@/assets/logo-white.png')"></v-img>
                    </v-card-title>
                    <v-card-text id="login-form">
                        <v-btn
                        block
                        elevation="0"
                        class="mt-8"
                        color="primary"
                        small
                        @click="is_sign_up=!is_sign_up"
                        >
                        {{is_sign_up ? 'INICIA SESIÓN' : 'REGÍSTRATE'}}
                        </v-btn>
                        <v-row class="my-7">
                            <v-col cols="5">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="2">
                                <span class="text-body-1 d-flex justify-center mt-n2">o</span>
                            </v-col>
                            <v-col cols="5">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-scroll-x-transition leave-absolute>
                            <v-form v-show="!is_sign_up">
                                <v-text-field
                                    prepend-icon="mdi-at"
                                    v-model="data_login.email"
                                    label="Email"
                                    type="text"
                                    outlined
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock"
                                    v-model="data_login.password"
                                    label="Contraseña"
                                    type="password"
                                    outlined
                                ></v-text-field>
                                <v-checkbox
                                    class="ml-8"
                                    v-model="data_login.keep_session"
                                    label="Mantener la sesión iniciada"
                                ></v-checkbox>
                            </v-form>
                        </v-scroll-x-transition>
                        <v-scroll-x-transition leave-absolute>
                            <v-form v-show="is_sign_up">
                                <v-row class="pa-7">
                                    <v-col cols="6">
                                        <v-btn block small color="primary" :text="!data_sign_up.is_business" @click="data_sign_up.is_business = true">NEGOCIO</v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn block small color="primary" :text="data_sign_up.is_business" @click="data_sign_up.is_business = false">PARTICULAR</v-btn>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    v-show="data_sign_up.is_business"
                                    prepend-icon="mdi-domain"
                                    v-model="data_sign_up.business_name"
                                    label="Nombre de la empresa"
                                    type="text"
                                    outlined
                                    :rules="[rules.business_name_required]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-at"
                                    v-model="data_sign_up.email"
                                    label="Email"
                                    type="text"
                                    outlined
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock"
                                    v-model="data_sign_up.password"
                                    label="Contraseña"
                                    :type="show_password ? 'text' : 'password'"
                                    outlined
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show_password = !show_password"
                                    loading
                                    :rules="[rules.required, rules.min]"
                                >
                                    <template v-slot:progress>
                                        <v-progress-linear
                                        :value="passwordReliability.value"
                                        :color="passwordReliability.color"
                                        class="mt-1"
                                        absolute
                                        height="3"
                                        ></v-progress-linear>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock-check"
                                    v-model="data_sign_up.repeated_password"
                                    label="Repetir contraseña"
                                    :type="show_repeated_password ? 'text' : 'password'"
                                    outlined
                                    :append-icon="show_repeated_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show_repeated_password = !show_repeated_password"
                                    :rules="[rules.required, rules.same_password(data_sign_up.password, data_sign_up.repeated_password)]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-account"
                                    v-model="data_sign_up.name"
                                    label="Nombre"
                                    type="text"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-card-account-details"
                                    v-model="data_sign_up.last_name"
                                    label="Apellidos"
                                    type="text"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-form>
                        </v-scroll-x-transition>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="allRequired" block @click="is_sign_up ? signIn() : login()">{{is_sign_up ? 'REGISTRARSE' : 'ENTRAR'}}</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-card-subtitle v-show="!is_sign_up" class="mb-0 pb-0">
                            <span>¿Olvidaste tu contraseña?</span>
                            <v-btn color="primary" text small disabled
                            >
                            RECUPERAR
                            </v-btn>
                        </v-card-subtitle>
                    </v-card-actions>
                    <v-card-actions class="py-10">
                        <v-img max-height="20" contain class="d-flex justify-center mt-n5" :src="require('@/assets/logo-white.png')"></v-img>
                    </v-card-actions>
                        <v-snackbar
                        v-model="alert_snackbar"
                        color="transparent"
                        elevation="0"
                        >
                            <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            light
                            elevation="2"
                            >
                                {{alert_message}}
                            </v-alert>
                        </v-snackbar>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
    name: 'LoginView',
    data () {
    return {
        url_server: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`,
        login_dialog: true,
        is_sign_up: false,
        alert_snackbar: false,
        alert_message: '',
        show_password: false,
        show_repeated_password: false,
        rules: {
          required: value => !!value || 'Este campo es necesario',
          business_name_required: value => {
            if (this.data_sign_up.is_business) {
                return !!value || 'Este campo es necesario'
            }
          },
          min: v => v.length >= 4 || 'Mínimo 4 characters',
          email: email => /.+@.+\..+/.test(email) || 'Email no válido',
          same_password: (value, repeatedPassword) => value === repeatedPassword || 'Las contraseñas no coinciden',
        },
        data_login: {
            email: '',
            password: '',
            keep_session: false,
        },
        data_sign_up: {
            is_business: true,
            email: '',
            password: '',
            repeated_password: '',
            name: '',
            last_name: ''
        },
    }
    },
    computed: {
        passwordReliability () {
            if (this.data_sign_up.password.length === 0) {
                return {
                    value: 0,
                    color: ''
                }
            } else if (this.data_sign_up.password.length < 6) {
                return {
                    value: 25,
                    color: 'red'
                }
            } else if (this.data_sign_up.password.length < 8) {
                return {
                    value: 50,
                    color: 'orange'
                }
            } else {
                return {
                    value: 100,
                    color: 'green'
                }
            }
        },
        allRequired () {
            if (this.is_sign_up) {
                return !(this.data_sign_up.email.length > 0 && this.data_sign_up.password.length > 0 && this.data_sign_up.repeated_password.length > 0 && this.data_sign_up.name.length > 0 && this.data_sign_up.last_name.length > 0)
            } else {
                return !(this.data_login.email.length > 0 && this.data_login.password.length > 0)
            }
        }
    },
    mounted () {
        console.log(this.$route.path+' mounted')
    },
    methods: {
        ...mapActions(['setUser']),
        close () {
            this.login_dialog = false
            this.$nextTick(() => {
                this.$router.back()
            })
        },
        log (msg) {
            this.alert_message = msg
            this.alert_snackbar = true 
        },
        login () {
            this.log(this.url_server)
            // Si los campos no estan vacios
            if (this.data_login.email.length > 0 && this.data_login.password.length > 0) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }
                // Se llama a la api y se guardan los datos obtenidos
                axios.post(`${this.url_server}/login`, this.data_login, config)
                .then((res) => {
                    this.log (res)
                    if (res.status === 200) {
                        const user = {
                            id: res.data.id,
                            name: res.data.name,
                            last_name: res.data.last_name,
                            email: res.data.email,
                            token: res.data.token
                        }
                        this.setUser(user)
                        this.$router.push('/c')
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.alert_message = 'Usuario o contraseña incorrectos'
                        this.alert_snackbar = true
                    }
                })
            } else {
                this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
                this.alert_snackbar = true
            }
        },
        signIn () {
            // Si los campos no estan vacios
            if (this.data_sign_up.email.length > 0 && this.data_sign_up.password.length > 0 && this.data_sign_up.repeated_password.length > 0 && this.data_sign_up.name.length > 0 && this.data_sign_up.last_name.length > 0) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }
                // Se llama a la api y se guardan los datos obtenidos
                axios.post(`${this.url_server}/signIn`, this.data_sign_up, config)
                .then((res) => {
                    if (res.status === 200) {
                        const user = {
                            id: res.data.id,
                            name: res.data.name,
                            last_name: res.data.last_name,
                            email: res.data.email,
                            token: res.data.token
                        }
                        this.setUser(user)
                        this.$router.push('/home')
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.alert_message = 'Se ha producido un error al dar de alta el usuario.'
                        this.alert_snackbar = true
                    }
                })
            } else {
                this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
                this.alert_snackbar = true
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section h1 {
    font-weight: 700;
    font-size: min(2.8rem, 9vw);
}
.section h2 {
    font-weight: 300;
    font-size: min(1.7rem, 6vw);
    /* position: relative; */
}

.feature-card-title {
    /* color: #02589e; */
    font-weight: 800;
    font-size: 25px;
}
.feature-card-price {
    color: #02589e;
    font-weight: 300;
    font-size: 30px;
}
.feature-card-text {
    color: #666;
    font-weight: 300;
    font-size: 15px;
}

</style>
