import Vue from 'vue'
import App from './App.vue'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import store from '@/plugins/store' // path to vuetify export

Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

/* eslint-disable */
new Vue({
  el: '#app',
  router,
  vuetify,
  store,
  components: { App },
  template: '<App/>'
})
