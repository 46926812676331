<template>
    <v-app id="app-menu">
        <AppBar @toggleDrawer="toggleDrawer"/>
        <NavigationDrawer v-bind:toggleDrawer="drawerClick"/>
        <router-view />
        <!-- <BottomNavigation/> -->
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppBar from '@/components/Customer/AppBar.vue'
import NavigationDrawer from '@/components/Customer/NavigationDrawer.vue'
// import BottomNavigation from './BottomNavigation.vue'

export default {
    name: 'CustomerView',
    components: { NavigationDrawer, AppBar},
    data () {
        return {
            drawerClick: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        toggleDrawer () {
            this.drawerClick = !this.drawerClick
        },
    },
    created () {
        console.log(this.user)
        if (this.user === null) {
            // this.$router.push('/login')
        }
    }
}
</script>

<style scoped>

</style>
