<template>
    <v-container class="ma-0 pa-0">
        <v-card
            :loading="loading"
            class="mx-auto"
        >
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>

            <!-- <v-img
            height="250"
            src="https://www.mejoresdiscotecasmadrid.es/wp-content/uploads/2019/05/Discoteca-teatro-barcelo-listas-reservados-madrid.jpg"
            ></v-img> -->

            <v-card-title class="mt-16 font-weight-black mb-n3"><h3>Publica tu subasta</h3></v-card-title>

            <v-divider class="mx-4"></v-divider>
            <v-card-title>Título</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="editedBid.title"
                    label="Título para la subasta"
                    outlined
                    color="primary"
                    hide-details
                ></v-text-field>
            </v-card-text>

            <v-card-title>Fotos</v-card-title>
            <v-card-text>
                <span>0/5 fotos</span>
                <v-alert
                type="info"
                color="primary"
                colored-border
                border="left"
                elevation="1"
                >
                Añade una foto para tu subasta
                </v-alert>

                <!-- <v-card elevation="0"> -->
                    <!-- <v-card-text> -->
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-file-input
                                    v-model="editedBid.file"
                                    label="Foto"
                                    prepend-icon="mdi-camera"
                                    show-size
                                    outlined
                                    color="primary"
                                    accept="image/*"
                                    hide-details
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-img
                                    v-if="file"
                                    :src="file"
                                    max-width="100%"
                                    max-height="100%"
                                    contain
                                ></v-img>
                            </v-col>
                        </v-row>
                    <!-- </v-card-text> -->
                <!-- </v-card> -->

            </v-card-text>


            <v-divider class="mx-4"></v-divider>
            <v-card-title>Descripción</v-card-title>
            <v-card-text>
                <v-alert
                    type="info"
                    color="primary"
                    colored-border
                    border="left"
                    elevation="1"
                    >
                    Añade una descripción para tu subasta. Recuerda nombrar los detalles que hacen único a tu local.
                </v-alert>
                <v-textarea
                    v-model="editedBid.description"
                    label="Descripción"
                    outlined
                    color="primary"
                    hide-details
                ></v-textarea>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
            <v-card-title>Etiquetas</v-card-title>
            <!-- <v-card-text>
                <v-autocomplete
                    v-model="editedBid.labels"
                    label="Etiquetas"
                    outlined
                    color="primary"
                    hide-details
                    :items="['Categoría 1', 'Categoría 2', 'Categoría 3']"
                    multiple
                >
                    <template v-slot:selection="data">
                        <v-chip
                        label
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                        class="primary"
                        >
                        {{ data.item }}
                        </v-chip>
                    </template>    
                </v-autocomplete>
            </v-card-text> -->

            <v-divider class="mx-4"></v-divider>
            <v-card-title>Ajustes de la subasta</v-card-title>
            <v-card-text>
                <v-autocomplete
                    v-model="editedBid.pricing_type"
                    label="Etiquetas"
                    outlined
                    color="primary"
                    hide-details
                    :items="[
                        { text: 'Por días de la semana', value: 'weekly' },
                        { text: 'Calendario', value: 'global' }
                    ]"
                > 
                </v-autocomplete>
            </v-card-text>


            <v-card-text v-if="editedBid.pricing_type == 'global'">
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-sheet
                        max-width="100%"
                    >
                    <v-slide-group
                        v-model="model"
                        class="pa-0"
                        mandatory
                        :show-arrows="false"
                        center-active
                        >
                            <v-slide-item
                                v-for="n in 15"
                                :key="n"
                                v-slot="{ active, toggle }"
                            >
                                <v-badge 
                                    overlap 
                                    bordered 
                                    class="mt-2 ml-1"
                                    :value="n == 5 ? false : false"
                                    color="warning"
                                >
                                    <v-card
                                    :color="active ? 'primary' : n % 2 == 0 ? 'grey' : 'grey lighten-3'"
                                    class="ma-1"
                                    height="120"
                                    width="80"
                                    @click="toggle"
                                    :disabled="n % 2 == 0"
                                    :class="n % 2 == 0 ? 'elevation-0' : ''"
                                    >
                                        <!-- <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-scale-transition>
                                                <v-icon
                                                    v-if="active"
                                                    color="white"
                                                    size="48"
                                                    v-text="'mdi-close-circle-outline'"
                                                ></v-icon>
                                            </v-scale-transition>
                                        </v-row> -->
                                        <v-card-text class="px-0">
                                            <v-row :class="active ? 'grey--text text--lighten-3' : ''">
                                                <v-col cols="12" class="d-flex justify-center py-2 pb-0">
                                                    <span style="font-size:14px;" class="mt-1">LUN</span>
                                                    <!-- <v-chip label style="font-size:15px;" small class="mt-1 py-2 px-2" color="lighten">L</v-chip> -->
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center py-3">
                                                    <span style="font-size:35px;">{{ n }}</span>
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center py-0">
                                                    <span style="font-size:9px;">DICIEMBRE</span>
                                                </v-col>
                                                <v-col cols="12" class="d-flex justify-center my-n5" v-if="n % 2 == 0">
                                                    <span style="font-size:9px;" class="white--text">SIN SUBASTAS</span>
                                                    <!-- <v-chip label style="font-size:15px;" small class="mt-1 py-2 px-2">L</v-chip> -->
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-badge>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-row>
            </v-card-text>
            <v-card-text 
            v-else-if="editedBid.pricing_type == 'weekly'"
            class="d-flex justify-center"
            >
                <v-chip 
                v-for="day in ['L', 'M', 'X', 'J', 'V', 'S', 'D']" 
                :key="day"
                large
                class="pa-4 mr-1"
                label
                :class="editedBid.weekly_days.split('').includes(day) ? 'primary' : 'grey lighten-3'"
                @click="editedBid.weekly_days = editedBid.weekly_days.split('').includes(day) ? editedBid.weekly_days.replace(day, '') : editedBid.weekly_days + day"
                >
                    {{ day }}
                </v-chip>
            </v-card-text>


            <v-divider class="mx-4 mt-2"></v-divider>
            <!-- <v-card-title class="font-weight-black">Pujar</v-card-title> -->
            
            <v-card-text>
                <!-- <v-btn block color="primary" outlined><v-icon left>mdi-plus</v-icon>Nueva subasta</v-btn> -->
                <!-- <v-row
                    align="center"
                    class="mx-0 mt-4"
                >
                    <v-col cols="5">
                        <span>Puja actual:</span>
                    </v-col>
                    <v-col cols="7">
                        <span label outlined color="secondary"><h2>356,23€<v-chip label x-small class="ml-3">39 pujas</v-chip></h2></span>
                    </v-col>
                </v-row> -->
                <v-card outlined elevation="0" class="mb-4 py-3 px-1">
                    <v-row
                        align="center"
                        class="mx-0"
                    >
                        <v-col cols="7">
                            <span><b>Hora de reserva:</b></span>
                        </v-col>
                        <v-col cols="5">
                            <span label outlined color="secondary"><v-chip label small class="ml-0" color="primary">Sábado, 22:00</v-chip></span>
                        </v-col>
                        <v-col cols="8" class="mt-0 pt-0">
                            <!-- <v-text-field
                            label="Horas diferencia"
                            persistent-hint
                            hint="Hora referencia: 23:00"
                            outlined
                            suffix="horas"
                            >
                            </v-text-field> -->
                            <v-autocomplete
                            label="Elige una hora"
                            :items="[
                            '08:00',
                            '08:30',
                            '09:00',
                            '09:30',
                            '10:00',
                            '10:30',
                            '11:00',
                            '11:30',
                            '12:00',
                            '12:30',
                            '13:00',
                            '13:30',
                            '14:00',
                            '14:30',
                            '15:00',
                            '15:30',
                            '16:00',
                            '16:30',
                            '17:00',
                            '17:30',
                            '18:00',
                            '18:30',
                            '19:00',
                            '19:30',
                            '20:00',
                            '20:30',
                            '21:00',
                            '21:30',
                            '22:00',
                            '22:30',
                            '23:00',
                            '23:30',
                            '00:00 [+1]',
                            '00:30 [+1]',
                            '01:00 [+1]',
                            '01:30 [+1]',
                            '02:00 [+1]',
                            '02:30 [+1]',
                            '03:00 [+1]',
                            '03:30 [+1]',
                            ]"
                            outlined
                            hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="7" class="pr-0">
                            <span><b>Apertura de la subasta:</b></span>
                        </v-col>
                        <v-col cols="5">
                            <span label outlined color="secondary"><v-chip label small class="ml-0" color="primary">Martes, 20:00</v-chip></span>
                        </v-col>
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field
                            label="Dias"
                            persistent-hint
                            outlined
                            suffix="d"
                            hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field
                            label="Horas"
                            persistent-hint
                            outlined
                            suffix="h"
                            append-outer-icon="mdi-information"
                            @click:append-outer="dialog = true"
                            hide-details
                            >
                            </v-text-field>
                            <v-dialog v-model="dialog">
                                <v-card>
                                    <v-card-text>La subasta se cerrará X días ...</v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-n2 ml-1">
                            <span>Previos a la reserva</span>
                        </v-col>
                        <v-col cols="7">
                            <span><b>Cierre de la subasta:</b></span>
                        </v-col>
                        <v-col cols="5">
                            <span label outlined color="secondary"><v-chip label small class="ml-0" color="primary">Viernes, 23:00</v-chip></span>
                        </v-col>
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field
                            label="Dias"
                            persistent-hint
                            outlined
                            suffix="d"
                            hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field
                            label="Horas"
                            persistent-hint
                            outlined
                            suffix="h"
                            append-outer-icon="mdi-information"
                            @click:append-outer="dialog = true"
                            hide-details
                            >
                            </v-text-field>
                            <v-dialog v-model="dialog">
                                <v-alert
                                    type="info"
                                    color="primary"
                                    colored-border
                                    border="left"
                                    elevation="1"
                                    >
                                    La subasta se cerrará X días antes de la reserva.
                                </v-alert>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-n2 ml-1">
                            <span>Previos a la reserva</span>
                        </v-col>
                        <v-col cols="7">
                            <span><b>Precio de salida:</b></span>
                        </v-col>
                        <v-col cols="5">
                            <!-- <span label outlined color="secondary"><v-chip label small class="ml-0" color="primary">Sábado, 22:00</v-chip></span> -->
                        </v-col>
                        <v-col cols="7" class="mt-0 pt-0">
                            <v-text-field
                            label="Precio de salida"
                            outlined
                            hide-details
                            suffix="€"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="7">
                            <span><b>Cantidad:</b></span>
                        </v-col>
                        <v-col cols="5">
                            <!-- <span label outlined color="secondary"><v-chip label small class="ml-0" color="primary">Sábado, 22:00</v-chip></span> -->
                        </v-col>
                        <v-col cols="5" class="mt-0 pt-0">
                            <v-text-field
                            label="Cantidad"
                            outlined
                            hide-details
                            type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="7" class="mt-0 pt-0">
                            <v-btn fab outlined class="mr-2 elevation-1 mx-2" color="teal"><v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn fab outlined class="mr-2 elevation-1 mx-2" color="teal"><v-icon>mdi-minus</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-n2 ml-1">
                            <span>Unidades subastadas</span>
                        </v-col>
                    </v-row>
                </v-card>
                <v-btn block color="primary"><v-icon left>mdi-content-save-plus</v-icon>Añadir subasta</v-btn>
                <v-alert
                    type="info"
                    color="primary"
                    colored-border
                    border="left"
                    elevation="1"
                    class="mt-4"
                    >
                    Tu subasta no será visible a otros usuarios hasta que no la hayas publicado.
                </v-alert>
                
            </v-card-text>
            
            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-btn block color="primary"><v-icon left>mdi-send</v-icon>Publicar</v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
// import GadgetDialog from './GadgetDialog.vue'

export default {
  name: 'EditBidView',
  components: {
    // GadgetDialog
  },
  data () {
    return {
        dialog: false,
        editedBid: {
            id: null,
            title: '',
            description: '',
            labels: [],
            pricing_type: 'weekly',
            weekly_days: 'JVS',
            price: 356.23,
        }
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {
    // ...mapActions(['getGadgets', 'executeAction', 'getSites', 'getTypes']),
    // newGadget (siteId) {
    //     this.editedSiteId = siteId
    //     this.showGadgetDialog = true
    // },
    // toggleGadget (gadget) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: !gadget.state,
    //         action_id: !gadget.state ? gadget.state_on_action_id : gadget.state_off_action_id
    //     }
    //     this.executeAction(dataAction)
    //     .then(response => {
    //         if (response.data.success) {
    //             this.getGadgets()
    //             this.close()
    //         } else {
    //             console.log(response.data.message)
    //         }
    //     })
    //     .finally(() => {
    //         gadget.loading = false
    //     })
    // },
    // setGadgetState (gadget, state) {
    //     gadget.loading = true
    //     const dataAction = {
    //         function: 'executeAction',
    //         id: gadget.id,
    //         state: state,
    //         action_id: state ? gadget.state_on_action_id : gadget.state_off_action_id,
    //     }
    //     if (state !== gadget.state) {
    //         this.executeAction(dataAction)
    //         .then(response => {
    //             if (response.data.success) {
    //                 this.getGadgets()
    //                 this.close()
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .finally(() => {
    //             gadget.loading = false
    //         })
    //     } else {
    //         gadget.loading = false
    //     }
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
