/* eslint-disable indent */
<template>
    <v-app id="app-menu">
        <v-container class="ma-0 pa-0">
            <v-row class="d-flex justify-center align-center ma-0 pb-8 primary">
                <v-col cols="0" sm="6" md="5" class="px-6 d-none d-sm-block">
                    <h1 class="blue-grey--text text--lighten-3 ">¡Optimiza tu negocio con BidApp!</h1>
                    <h2 class="grey--text text--darken-3">Empieza a subastar tus servicios, ¡haz click aquí!</h2>
                    <v-btn class="text-capitalize mt-3" color="primary" rounded>Comenzar</v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="d-flex justify-center mb-n16 pt-16">
                    <!-- <v-img ref="background_1" :src="require('@/assets/img/ice-bucket.png')" class="my-10" max-width="280"></v-img> -->
                    <v-carousel
                    hide-delimiters
                    hide-controls
                    :show-arrows="false"
                    class="mb-n8"
                    cycle
                    :interval="5000"
                    max-height="300"
                    >
                        <v-carousel-item
                        v-for="(img,i) in images"
                        :key="i"
                        :src="img.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                        ></v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12" sm="0" md="0" class="px-6 d-block d-sm-none section">
                    <h1 class="white--text text--lighten-3 ">¡Optimiza tu discoteca con BidApp!</h1>
                    <h2 class="white--text text--darken-3">Empieza a subastar tus servicios, ¡haz click aquí!</h2>
                    <v-btn class="mt-3" dark color="blue-grey darken-3">Comenzar</v-btn>
                </v-col>
            </v-row>
            <v-img ref="background_3" :src="require('@/assets/img/wave-mobile.svg')" class="my-0"></v-img>
            <v-row class="ma-0 pa-0 d-flex justify-center">
                <v-col cols="12" md="4" class="ma-0 px-0">
                    <v-card
                    class="transparent px-7"
                    width="100%"
                    height="100%"
                    flat
                    >
                        <v-card-title class="d-flex justify-center mb-3">
                                <v-img
                                class="ml-n2"
                                :src="require('@/assets/img/bottle.png')"
                                height="100%"
                                contain>
                                </v-img>
                        </v-card-title>
                        <span class="d-flex justify-center teal--text text-center feature-card-title">Incrementa tus ingresos</span>
                        <span class="mt-3 d-flex justify-center feature-card-text text-center">
                            Oferta tus servicios mediante subastas para conseguir siempre el mejor precio según la demanda. Configura tú mismo la subasta según tus intereses de forma sencilla e intuitiva.
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 d-flex justify-center">
                <v-col cols="12" md="4" class="ma-0 px-0">
                    <v-card
                    class="white px-7"
                    width="100%"
                    height="100%"
                    flat
                    >
                        <v-card-title class="d-flex justify-center mb-3">
                                <v-img
                                class="ml-n2"
                                :src="require('@/assets/img/party.png')"
                                height="100%"
                                contain>
                                </v-img>
                        </v-card-title>
                        <span class="d-flex justify-center teal--text text-center feature-card-title">Aumenta la reputación de tu negocio</span>
                        <span class="mt-3 d-flex justify-center feature-card-text text-center">
                            Añade un toque de distinción a tu producto subastándolo. Genera interés por tus servicios, añade la adrenalina de pujar y la satisfacción de ganar la subasta. Refuerza la imagen de tu negocio mientras aumentas tus ingresos.
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 d-flex justify-center">
                <v-col cols="12" md="4" class="ma-0 px-0">
                    <v-card
                    class="white px-7"
                    width="100%"
                    height="100%"
                    flat
                    >
                        <v-card-title class="d-flex justify-center mb-3">
                                <v-img
                                class="ml-n2"
                                :src="require('@/assets/img/disco.png')"
                                height="100%"
                                contain>
                                </v-img>
                        </v-card-title>
                        <span class="d-flex justify-center teal--text text-center feature-card-title">Recursos útiles para preparar tu subasta</span>
                        <span class="mt-3 d-flex justify-center feature-card-text text-center">
                            Encuentra consejos para fomentar la competitividad en tus subastas, orientarlas a tu público objetivo y explotarlas como herramienta de promoción.
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                v-model="login_dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card class="elevation-2" dark>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-btn icon large @click="login_dialog=false">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-title class="mt-1">
                        <v-img max-height="65" contain class="d-flex justify-center " :src="require('@/assets/logo-white.png')"></v-img>
                    </v-card-title>
                    <v-card-text id="login-form">
                        <v-btn
                        block
                        elevation="0"
                        class="mt-8"
                        color="primary"
                        small
                        @click="is_sign_in=!is_sign_in"
                        >
                        {{is_sign_in ? 'INICIA SESIÓN' : 'REGÍSTRATE'}}
                        </v-btn>
                        <v-row class="my-7">
                            <v-col cols="5">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="2">
                                <span class="text-body-1 d-flex justify-center mt-n2">o</span>
                            </v-col>
                            <v-col cols="5">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-scroll-x-transition leave-absolute>
                            <v-form v-show="!is_sign_in">
                                <v-text-field
                                    prepend-icon="mdi-at"
                                    v-model="data_login.email"
                                    label="Email"
                                    type="text"
                                    outlined
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock"
                                    v-model="data_login.password"
                                    label="Contraseña"
                                    type="password"
                                    outlined
                                ></v-text-field>
                                <v-checkbox
                                    class="ml-8"
                                    v-model="data_login.keep_session"
                                    label="Mantener la sesión iniciada"
                                ></v-checkbox>
                            </v-form>
                        </v-scroll-x-transition>
                        <v-scroll-x-transition leave-absolute>
                            <v-form v-show="is_sign_in">
                                <v-text-field
                                    prepend-icon="mdi-at"
                                    v-model="data_sign_in.email"
                                    label="Email"
                                    type="text"
                                    outlined
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock"
                                    v-model="data_sign_in.password"
                                    label="Contraseña"
                                    :type="show_password ? 'text' : 'password'"
                                    outlined
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show_password = !show_password"
                                    loading
                                    :rules="[rules.required, rules.min]"
                                >
                                    <template v-slot:progress>
                                        <v-progress-linear
                                        :value="passwordReliability.value"
                                        :color="passwordReliability.color"
                                        class="mt-1"
                                        absolute
                                        height="3"
                                        ></v-progress-linear>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-lock-check"
                                    v-model="data_sign_in.repeated_password"
                                    label="Repetir contraseña"
                                    :type="show_repeated_password ? 'text' : 'password'"
                                    outlined
                                    :append-icon="show_repeated_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show_repeated_password = !show_repeated_password"
                                    :rules="[rules.required, rules.same_password(data_sign_in.password, data_sign_in.repeated_password)]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-account"
                                    v-model="data_sign_in.name"
                                    label="Nombre"
                                    type="text"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-card-account-details"
                                    v-model="data_sign_in.last_name"
                                    label="Apellidos"
                                    type="text"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-form>
                        </v-scroll-x-transition>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="allRequired" block @click="is_sign_in ? signIn() : login()">{{is_sign_in ? 'REGISTRARSE' : 'ENTRAR'}}</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-card-subtitle v-show="!is_sign_in">
                            <span>¿Olvidaste tu contraseña?</span>
                            <v-btn color="primary" text small disabled
                            >
                            RECUPERAR
                            </v-btn>
                        </v-card-subtitle>
                    </v-card-actions>
                    <v-card-actions class="mt-2">
                        <v-img max-height="20" contain class="d-flex justify-center mt-n5" :src="require('@/assets/logo-white.png')"></v-img>
                    </v-card-actions>
                        <v-snackbar
                        v-model="alert_snackbar"
                        color="transparent"
                        elevation="0"
                        >
                            <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            light
                            elevation="2"
                            >
                                {{alert_message}}
                            </v-alert>
                        </v-snackbar>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex'
// import axios from 'axios'

export default {
    name: 'IntroView',
    data () {
    return {
        drawer: false,
        images: [
            {
                src: require('@/assets/img/ice-bucket.png'),
            },
            {
                src: require('@/assets/img/ice-bucket-2.png'),
            }
        ],
        footer_icons: [
            'mdi-facebook',
            'mdi-twitter',
            'mdi-instagram',
            'mdi-linkedin',
        ],
        login_dialog: false,
        is_sign_in: false,
        alert_snackbar: false,
        alert_message: '',
        show_password: false,
        show_repeated_password: false,
        rules: {
          required: value => !!value || 'Este campo es necesario',
          min: v => v.length >= 4 || 'Mínimo 4 characters',
          email: email => /.+@.+\..+/.test(email) || 'Email no válido',
          same_password: (value, repeatedPassword) => value === repeatedPassword || 'Las contraseñas no coinciden',
        },
        data_login: {
            email: '',
            password: '',
            keep_session: false,
        },
        data_sign_in: {
            email: '',
            password: '',
            repeated_password: '',
            name: '',
            last_name: ''
        },
    }
    },
    computed: {
        passwordReliability () {
            if (this.data_sign_in.password.length === 0) {
                return {
                    value: 0,
                    color: ''
                }
            } else if (this.data_sign_in.password.length < 6) {
                return {
                    value: 25,
                    color: 'red'
                }
            } else if (this.data_sign_in.password.length < 8) {
                return {
                    value: 50,
                    color: 'orange'
                }
            } else {
                return {
                    value: 100,
                    color: 'green'
                }
            }
        },
        allRequired () {
            if (this.is_sign_in) {
                return !(this.data_sign_in.email.length > 0 && this.data_sign_in.password.length > 0 && this.data_sign_in.repeated_password.length > 0 && this.data_sign_in.name.length > 0 && this.data_sign_in.last_name.length > 0)
            } else {
                return !(this.data_login.email.length > 0 && this.data_login.password.length > 0)
            }
        }
    },
    mounted () {
        document.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        ...mapActions(['setUser']),
        // handleScroll (evt) {
        //     console.log(evt)
        //     if (this.$refs.background_1 !== undefined) {
        //         // const scrollY = window.scrollY
        //         // const maxBackgroundSize = 120
        //         // const backgroundSize = scrollY / (maxBackgroundSize - 100) // increases as user scrolls
        //         // this.$refs.background_1.$el.style.transform = 'scale(' + (100 + backgroundSize * 3) / 100 + ')'
        //         // this.$refs.background_2.$el.style.transform = 'scale(' + (100 + backgroundSize * 2) / 100 + ')'
        //         // this.$refs.background_3.$el.style.transform = 'scale(' + (100 + backgroundSize * 0.1) / 100 + ')'
        //     }
        // },
        // login (e) {
        //     console.log (e)
        //     // Si los campos no estan vacios
        //     if (this.data_login.email.length > 0 && this.data_login.password.length > 0) {
        //         const config = {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //         }
        //         // Se llama a la api y se guardan los datos obtenidos
        //         axios.post(`${process.env.API_HOST}:${process.env.API_PORT}/login`, this.data_login, config)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 const user = {
        //                     id: res.data.id,
        //                     name: res.data.name,
        //                     last_name: res.data.last_name,
        //                     email: res.data.email,
        //                     token: res.data.token
        //                 }
        //                 this.setUser(user)
        //                 this.$router.push('/home')
        //                 // this.alert_message = 'OK'
        //                 // this.alert_snackbar = true
        //             }
        //         })
        //         .catch((error) => {
        //             if (error.response.status === 401) {
        //                 this.alert_message = 'Usuario o contraseña incorrectos'
        //                 this.alert_snackbar = true
        //             }
        //         })
        //     } else {
        //         this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
        //         this.alert_snackbar = true
        //     }
        // },
        // signIn (e) {
        //     console.log(e)
        //     // Si los campos no estan vacios
        //         console.log('OK2')
        //     if (this.data_sign_in.email.length > 0 && this.data_sign_in.password.length > 0 && this.data_sign_in.repeated_password.length > 0 && this.data_sign_in.name.length > 0 && this.data_sign_in.last_name.length > 0) {
        //         console.log('OK')
        //         const config = {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //         }
        //         // Se llama a la api y se guardan los datos obtenidos
        //         axios.post(`${process.env.API_HOST}:${process.env.API_PORT}/signIn`, this.data_sign_in, config)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 const user = {
        //                     id: res.data.id,
        //                     name: res.data.name,
        //                     last_name: res.data.last_name,
        //                     email: res.data.email,
        //                     token: res.data.token
        //                 }
        //                 this.setUser(user)
        //                 this.$router.push('/home')
        //                 // this.alert_message = 'OK'
        //                 // this.alert_snackbar = true
        //             }
        //         })
        //         .catch((error) => {
        //             if (error.response.status === 401) {
        //                 this.alert_message = 'Se ha producido un error al dar de alta el usuario.'
        //                 this.alert_snackbar = true
        //             }
        //         })
        //     } else {
        //         this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
        //         this.alert_snackbar = true
        //     }
        // },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section h1 {
    font-weight: 700;
    font-size: min(2.8rem, 9vw);
}
.section h2 {
    font-weight: 300;
    font-size: min(1.7rem, 6vw);
    /* position: relative; */
}

.feature-card-title {
    /* color: #02589e; */
    font-weight: 800;
    font-size: 25px;
}
.feature-card-price {
    color: #02589e;
    font-weight: 300;
    font-size: 30px;
}
.feature-card-text {
    color: #666;
    font-weight: 300;
    font-size: 15px;
}

</style>
