import Vue from 'vue'
import Router from 'vue-router'

import HomeView from '@/views/Home/HomeView'
import IntroView from '@/views/Home/IntroView'
import AboutView from '@/views/Home/AboutView'
import HelpView from '@/views/Home/HelpView'
import JoinUsView from '@/views/Home/JoinUsView'
import TrainingsView from '@/views/Home/TrainingsView'
import LoginView from '@/views/Home/LoginView'

import CustomerView from '@/views/Customer/CustomerView'
import BidView from '@/views/Customer/BidView'
import MyBidsView from '@/views/Customer/MyBidsView'
import CustomerConfigView from '@/views/Customer/CustomerConfigView'
import CustomerHelpView from '@/views/Customer/CustomerHelpView'

import BusinessView from '@/views/Business/BusinessView'
import EditBidView from '@/views/Business/EditBidView'
import PublishedBidsView from '@/views/Business/PublishedBidsView'
import BusinessConfigView from '@/views/Business/BusinessConfigView'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/intro',
            component: HomeView,
            components: {
                default: HomeView,
                // login: LoginView,
            },
            children: [
                {
                    path: '/intro',
                    name: 'IntroView',
                    // components: {
                    //     default: IntroView,
                    //     login: LoginView,
                    // },
                    component: IntroView
                },
                {
                    path: '/about',
                    name: 'AboutView',
                    // components: {
                    //     default: AboutView,
                    //     login: LoginView,
                    // },
                    component: AboutView
                },
                {
                    path: '/help',
                    name: 'HelpView',
                    // components: {
                    //     default: HelpView,
                    //     login: LoginView,
                    // },
                    component: HelpView,
                    // meta: { transition: 'slide-left' },
                },
                {
                    path: '/join-us',
                    name: 'JoinUsView',
                    // components: {
                    //     default: HelpView,
                    //     login: LoginView,
                    // },
                    component: JoinUsView,
                    // meta: { transition: 'slide-left' },
                },
                {
                    path: '/login',
                    name: 'LoginView',
                    component: LoginView,
                    // meta: { transition: 'slide-left' },
                },
                {
                    path: '/trainings',
                    name: 'TrainingsView',
                    component: TrainingsView,
                    // meta: { transition: 'slide-left' },
                },
            ]
        },
        {
            path: '/c/',
            name: 'customer',
            redirect: '/c/my-bids',
            component: CustomerView,
            children: [
                {
                    path: '/c/bid',
                    component: BidView
                },
                {
                    path: '/c/my-bids',
                    component: MyBidsView
                },
                {
                    path: '/c/config',
                    component: CustomerConfigView
                },
                {
                    path: '/c/help',
                    component: CustomerHelpView
                },
            ]
        },
        {
            path: '/b/',
            name: 'business',
            redirect: '/b/published-bids',
            component: BusinessView,
            children: [
                {
                    path: '/b/edit-bid',
                    component: EditBidView
                },
                {
                    path: '/b/published-bids',
                    component: PublishedBidsView
                },
                {
                    path: '/b/config',
                    component: BusinessConfigView
                },
            ]
        },
        // {
        //     path: '/:pathMatch(.*)*',
        //     component: LoginView
        // }
    ],
    mode: 'history',
})
