<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    >
        <v-card class="elevation-0 pb-16">
            <v-toolbar
                class="elevation-0"
                >
                <v-btn
                    icon
                    @click="back()"
                    large
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title class="my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Política de privacidad</v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card class="elevation-0 mx-4" v-for="item in privacy_policy" :key="'pp_'+item.id">
                <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                <v-divider v-if="item.title" class="mx-4"></v-divider>
                <v-card-text class="text-break" v-for="text in item.content" :key="text" style="font-size: 18px;" v-html="text">
                </v-card-text>
            </v-card>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
  name: 'PrivacyPolicyComponent',
  props: ['dialog'],
  data () {
    return {
        privacy_policy: [
            {
                id: 0,
                content: [
                    'La página web se compromete a proteger la privacidad de todos los usuarios que visitan nuestro sitio web. Esta política de privacidad explica cómo recopilamos, almacenamos y utilizamos la información personal de los usuarios. Lea detenidamente esta política de privacidad antes de utilizar nuestros servicios.'
                ]
            },
            {
                id: 1,
                title: 'Información recopilada',
                content: [
                    'La página web recopila información personal de los usuarios cuando se registran en nuestro sitio web. La información personal recopilada incluye nombre, dirección de correo electrónico, número de teléfono y dirección postal.',
                    'La página web también recopila información no personal de los usuarios cuando visitan nuestro sitio web. La información no personal recopilada incluye datos de tráfico, ubicación geográfica, tipo de navegador y sistema operativo.'
                ]
            },
            {
                id: 2,
                title: 'Uso de la información',
                content: [
                    'La página web utiliza la información personal de los usuarios para proporcionar los servicios solicitados. La página web también utiliza la información personal de los usuarios para enviar información sobre ofertas especiales y nuevos servicios.',
                    'La página web utiliza la información no personal de los usuarios para analizar el tráfico de nuestro sitio web y mejorar nuestros servicios.'
                ]
            },
            {
                id: 3,
                title: 'Compartir información',
                content: [
                    'La página web no vende, alquila o comparte la información personal de los usuarios con terceros. La página web puede compartir información personal de los usuarios con proveedores de servicios que trabajan en nuestro nombre, como proveedores de servicios de correo electrónico. Estos proveedores de servicios no tienen autorización para utilizar o divulgar la información personal de los usuarios de ninguna otra manera que lo permita la página web.',
                    'La página web puede divulgar información personal de los usuarios cuando sea necesario para cumplir con la ley o para proteger los derechos, la propiedad o la seguridad de la página web, nuestros usuarios o terceros.'
                ]
            },
            {
                id: 4,
                title: 'Seguridad',
                content: [
                    'Nos comprometemos a garantizar que la información de los usuarios esté segura. Para evitar el acceso no autorizado o la divulgación, hemos implementado medidas físicas, electrónicas y administrativas adecuadas para proteger y asegurar la información que recopilamos en línea.'
                ]
            },
            {
                id: 5,
                title: 'Enlaces a otros sitios web',
                content: [
                    'Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin embargo, una vez que el usuario haya utilizado estos enlaces para salir de nuestro sitio, debe tener en cuenta que no tenemos control sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de cualquier información que el usuario proporcione mientras visite estos sitios web y estos sitios no se rigen por esta política de privacidad. El usuario debe tener precaución y leer la política de privacidad aplicable al sitio web en cuestión.'
                ]
            },
            {
                id: 6,
                title: 'Control de la información personal',
                content: [
                    'El usuario puede restringir la recopilación o el uso de la información personal de la siguiente manera:',
                    'Cuando se le solicite rellenar un formulario en el sitio web, marque o desmarque la opción de recibir información por correo electrónico',
                    'Si ha marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento',
                    'Esta compañía no venderá, distribuirá ni cederá la información personal de los usuarios a terceros a menos que sea requerido por un juez con un orden judicial.'
                ]
            },
            {
                id: 7,
                title: 'Modificaciones',
                content: [
                    'Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Los usuarios serán notificados de las modificaciones y se les solicitará que acepten los nuevos términos antes de continuar utilizando los servicios de la página web.'
                ]
            },
            {
                id: 8,
                title: 'Aceptación de esta política',
                content: [
                    'Al utilizar nuestro sitio web, el usuario acepta esta política de privacidad. La página web se reserva el derecho de modificar esta política de privacidad en cualquier momento, por lo que se recomienda revisar esta página periódicamente.'
                ]
            },
            {
                id: 9,
                title: 'Contacto',
                content: [
                    'Si tiene alguna pregunta sobre esta política de privacidad, el uso de este sitio web o los servicios ofrecidos en él, puede contactar con nosotros a través de nuestro formulario de contacto.'
                ]
            },
            {
                id: 10,
                content: [
                    'Al utilizar los servicios de la página de subastas de reservados de discotecas, los usuarios aceptan la política de privacidad'
                ]
            }
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
