/* eslint-disable indent */
<template>
    <v-app id="app-menu">
        <v-container class="ma-0 pa-0">
            <v-card tile elevation="0">
                <v-img 
                :src="require('@/assets/img/about/about-background.png')" 
                height="550" 
                class="align-end"
                gradient="to top right, rgba(100,115,201,.44), rgba(25,32,72,.8)"
                >
                    <h1 class="white--text text-center px-5 mb-10">Únete a nuestro equipo y ayúdanos a crear la mejor plataforma de subastas de reservas</h1>
                    <v-card-title class="pa-0">
                        <v-img :src="require('@/assets/img/about/about-shape-1.png')"></v-img>
                    </v-card-title>
                </v-img>
            </v-card>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pt-9 pb-0">
                    <!-- <h2 class="text-center">¡Trabaja con nosotros para crear la próxima gran experiencia en línea como Desarrollador Web!</h2> -->
                    <h1 class="text-center">Sobre nosotros</h1>
                </v-col>
            </v-row>
            <v-card tile elevation="0">
                <v-card-text class="pa-7 text-center">
                    <!-- <h3>Sobre nosotros</h3> -->
                    En Bidapp VIP desarrollamos una innovadora plataforma de reservas mediante subastas. Esta plataforma permite pujar por un servicio ofertado regularmente en una subasta concreta para cada fecha.
                </v-card-text>
            </v-card>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pt-6 pb-0">
                    <!-- <h2 class="text-center">¡Trabaja con nosotros para crear la próxima gran experiencia en línea como Desarrollador Web!</h2> -->
                    <h1 class="text-center">Forma parte del equipo</h1>
                </v-col>
            </v-row>
            <v-card class="pa-4 ma-3 mt-8">

                <h3 class="primary--text">
                    <v-icon left color="primary">mdi-monitor-cellphone-star</v-icon>
                    Desarrollador web
                </h3>

                <v-card-text class="pl-2 pt-2">

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Sobre el puesto</h3>
                    <span>
                        El <b>ingeniero de software junior</b> será responsable de contribuir en el desarrollo de nuestra plataforma, tanto en el <b>front-end como en el back-end</b>.
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Tareas</h3>
                    <span>
                        <li>Desarrollo web <b>front-end y back-end</b></li>
                        <li>Gestión de <b>pago online</b></li>
                        <li><b>UI/UX</b></li>
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Requisitos</h3>
                    <span>
                        <li>Grado en <b>ingeniería informática o similar</b></li>
                        <li>Conocimientos de <b>UI/UX</b></li>
                        <li>Conocimientos de <b>bases de datos relacionales</b></li>
                        <li>Conocimientos de <b>frameworks Vuejs, Angular o React</b></li>
                        <li>Conocimientos de <b>NodeJS</b></li>
                        <li>Autonomía y eficiencia con <b>supervisión mínima</b></li>
                        <li>Se valorará positivamente experiencia previa en <b>metodología Agile</b></li>
                        <li>Se valorará positivamente conocimientos de <b>Big Data, y/o machine learning</b></li>
                        <li>Se valorará positivamente conocimientos de <b>diseño gráfico</b></li>
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Qué ofrecemos</h3>
                    <span>
                        <li>Formar parte de una start-up en plena expansión, con las consiguientes <b>oportunidades de crecimiento y de aprendizaje</b></li>
                        <li><b>Flexibilidad total</b>: Trabaja donde quieras cuando quieras</li>
                        <li>Un <b>ambiente de trabajo positivo</b></li>
                    </span>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" class="white--text px-4 py-3" href="mailto:recruiting@bidapp.vip?subject=Desarrollador web">Inscríbete</v-btn>
                </v-card-actions>
            </v-card>

            <v-card class="pa-4 ma-3 mt-0">

                <h3 class="primary--text">
                    <v-icon left color="primary">mdi-charity</v-icon>
                    Comercial junior
                </h3>

                <v-card-text class="pl-2 pt-2">

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Sobre el puesto</h3>
                    <span>
                        El comercial junior será responsable de conseguir contratos con nuevos clientes para que oferten sus servicios en nuestra plataforma.
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Tareas</h3>
                    <span>
                        <li>Búsqueda de <b>nuevos clientes</b> y firma de contratos con ellos</li>
                        <li>Participación activa en la exploración de <b>nuevas líneas de negocio</b></li>
                        <li>Colaboración en las <b>tareas de marketing</b>: Promoción de las pujas, <b>estrategia de precios</b>, o mejora de la <b>estrategia de e-commerce</b> entre otras</li>
                        <li>Soporte en <b>tareas financieras y de planificación estratégica</b> de la empresa</li>
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Requisitos</h3>
                    <span>
                        <li>Grado en <b>marketing, ADE, comercio o similar</b>. También se considerarán candidatos con una formación alternativa y MBA o similar.</li>
                        <li><b>Experiencia previa</b> en ventas de al menos 1 año</li>
                        <li>Se valorará experiencia previa en <b>e-commerce</b></li>
                        <li>Autonomía y eficiencia con <b>supervisión mínima</b></li>
                        <li>Español fluido obligatorio. <b>Inglés fluido</b> deseable.</li>
                    </span>

                    <h3 class="pb-2 mt-4 ml-n1 secondary--text">Qué ofrecemos</h3>
                    <span>
                        <li>Formar parte de una start-up en plena expansión, con las consiguientes <b>oportunidades de crecimiento y de aprendizaje</b></li>
                        <li><b>Flexibilidad total</b>: Trabaja donde quieras cuando quieras</li>
                        <li>Un <b>ambiente de trabajo positivo</b></li>
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" class="white--text px-4 py-3" href="mailto:recruiting@bidapp.vip?subject=Desarrollador web">Inscríbete</v-btn>
                </v-card-actions>
            </v-card>
            <!-- <v-row class="ma-0 pa-6 d-flex justify-center blue-grey lighten-5">
                <h1 class="mt-3 text-center">Tenemos una misión</h1>
                <v-col cols="12" md="4" class="ma-0 px-0">
                    <v-card
                    class="transparent px-7"
                    width="100%"
                    height="100%"
                    flat
                    >
                        <v-card-title class="d-flex justify-center mb-3">
                                <v-img
                                :src="require('@/assets/img/about/diana.png')"
                                height="150"
                                contain>
                                </v-img>
                        </v-card-title>
                        <span class="mt-3 d-flex justify-center feature-card-text text-center">
                            Aplicar el modelo clásico de subasta, siempre exitoso cuando ha sido utilizado con bienes de lujo, en el sector de los servicios de alta gama.
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-6 pb-12 d-flex justify-center blue-grey lighten-5">
                <h1 class="mt-3">Y unos valores</h1>
                <v-col cols="12" md="4" class="ma-0 px-0" v-for="item in company_values" :key="item.title">
                    <v-card
                    class="transparent px-7"
                    width="100%"
                    height="100%"
                    flat
                    >
                        <v-card-title class="d-flex justify-center mb-3">
                                <v-img
                                :src="item.src"
                                height="150"
                                contain>
                                </v-img>
                        </v-card-title>
                        <span class="d-flex justify-center teal--text text-center feature-card-title">{{item.title}}</span>
                        <span class="mt-3 d-flex justify-center feature-card-text text-center">
                            {{item.text}}
                        </span>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-container>
    </v-app>
</template>

<script>
// import { mapActions } from 'vuex'
// import axios from 'axios'
// const path = require('path')

export default {
    name: 'JoinUsView',
    data () {
    return {
        drawer: false,
        company_values: [
            {
                src: require('@/assets/img/about/quality.png'),
                title: 'Compromiso de calidad',
                text: 'Trabajar exclusivamente con profesionales y servicios de calidad contrastada'
            },
            {
                src: require('@/assets/img/about/ethics.png'),
                title: 'Selección de servicios ética',
                text: 'No ofrecemos bienes y servicios básicos que puedan conducir a la especulación con productos de primera necesidad. Se subastan exclusivamente servicios de ocio de alta gama'
            },
            {
                src: require('@/assets/img/about/client.png'),
                title: 'Orientación al cliente',
                text: 'La satisfacción del cliente es vital. Por ello, se facilitará a los clientes medios para proporcionar su opinión y tenerla en cuenta en las futuras actualizaciones.'
            },
            {
                src: require('@/assets/img/about/innovation.png'),
                title: 'Innovación',
                text: 'Bidapp VIP, aunque propono un modelo de negocio ya existente, lo plantea en un entorno y con un enfoque totalmente nuevo. La innovación es parte de nuestro ADN y por tanto internamente se ejerce un modelo de liderazgo participativo, en el que los empleados tienen autonomía y libertad para liderar sus propias propuestas.'
            },
            {
                src: require('@/assets/img/about/growth.png'),
                title: 'Espíritu de mejora',
                text: 'La importancia de la opinión del cliente y de la innovación se encuadran en un proceso de mejora continuo. Mañana se debe ofrecer a nuestros clientes un servicio mejor del que se ofrece hoy.'
            },
        ],
        // images: [
        //     {
        //         src: require('@/assets/img/ice-bucket.png'),
        //     },
        //     {
        //         src: require('@/assets/img/ice-bucket-2.png'),
        //     }
        // ],
        // login_dialog: false,
        // is_sign_in: false,
        // alert_snackbar: false,
        // alert_message: '',
        // show_password: false,
        // show_repeated_password: false,
        // rules: {
        //   required: value => !!value || 'Este campo es necesario',
        //   min: v => v.length >= 4 || 'Mínimo 4 characters',
        //   email: email => /.+@.+\..+/.test(email) || 'Email no válido',
        //   same_password: (value, repeatedPassword) => value === repeatedPassword || 'Las contraseñas no coinciden',
        // },
        // data_login: {
        //     email: '',
        //     password: '',
        //     keep_session: false,
        // },
        // data_sign_in: {
        //     email: '',
        //     password: '',
        //     repeated_password: '',
        //     name: '',
        //     last_name: ''
        // },
    }
    },
    computed: {
        // passwordReliability () {
        //     if (this.data_sign_in.password.length === 0) {
        //         return {
        //             value: 0,
        //             color: ''
        //         }
        //     } else if (this.data_sign_in.password.length < 6) {
        //         return {
        //             value: 25,
        //             color: 'red'
        //         }
        //     } else if (this.data_sign_in.password.length < 8) {
        //         return {
        //             value: 50,
        //             color: 'orange'
        //         }
        //     } else {
        //         return {
        //             value: 100,
        //             color: 'green'
        //         }
        //     }
        // },
        // allRequired () {
        //     if (this.is_sign_in) {
        //         return !(this.data_sign_in.email.length > 0 && this.data_sign_in.password.length > 0 && this.data_sign_in.repeated_password.length > 0 && this.data_sign_in.name.length > 0 && this.data_sign_in.last_name.length > 0)
        //     } else {
        //         return !(this.data_login.email.length > 0 && this.data_login.password.length > 0)
        //     }
        // }
    },
    mounted () {
        // document.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        // ...mapActions(['setUser']),
        // handleScroll (evt) {
        //     console.log(evt)
        //     if (this.$refs.background_1 !== undefined) {
        //         // const scrollY = window.scrollY
        //         // const maxBackgroundSize = 120
        //         // const backgroundSize = scrollY / (maxBackgroundSize - 100) // increases as user scrolls
        //         // this.$refs.background_1.$el.style.transform = 'scale(' + (100 + backgroundSize * 3) / 100 + ')'
        //         // this.$refs.background_2.$el.style.transform = 'scale(' + (100 + backgroundSize * 2) / 100 + ')'
        //         // this.$refs.background_3.$el.style.transform = 'scale(' + (100 + backgroundSize * 0.1) / 100 + ')'
        //     }
        // },
        // login (e) {
        //     console.log (e)
        //     // Si los campos no estan vacios
        //     if (this.data_login.email.length > 0 && this.data_login.password.length > 0) {
        //         const config = {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //         }
        //         // Se llama a la api y se guardan los datos obtenidos
        //         axios.post(`${process.env.API_HOST}:${process.env.API_PORT}/login`, this.data_login, config)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 const user = {
        //                     id: res.data.id,
        //                     name: res.data.name,
        //                     last_name: res.data.last_name,
        //                     email: res.data.email,
        //                     token: res.data.token
        //                 }
        //                 this.setUser(user)
        //                 this.$router.push('/home')
        //                 // this.alert_message = 'OK'
        //                 // this.alert_snackbar = true
        //             }
        //         })
        //         .catch((error) => {
        //             if (error.response.status === 401) {
        //                 this.alert_message = 'Usuario o contraseña incorrectos'
        //                 this.alert_snackbar = true
        //             }
        //         })
        //     } else {
        //         this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
        //         this.alert_snackbar = true
        //     }
        // },
        // signIn (e) {
        //     console.log(e)
        //     // Si los campos no estan vacios
        //         console.log('OK2')
        //     if (this.data_sign_in.email.length > 0 && this.data_sign_in.password.length > 0 && this.data_sign_in.repeated_password.length > 0 && this.data_sign_in.name.length > 0 && this.data_sign_in.last_name.length > 0) {
        //         console.log('OK')
        //         const config = {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //         }
        //         // Se llama a la api y se guardan los datos obtenidos
        //         axios.post(`${process.env.API_HOST}:${process.env.API_PORT}/signIn`, this.data_sign_in, config)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 const user = {
        //                     id: res.data.id,
        //                     name: res.data.name,
        //                     last_name: res.data.last_name,
        //                     email: res.data.email,
        //                     token: res.data.token
        //                 }
        //                 this.setUser(user)
        //                 this.$router.push('/home')
        //                 // this.alert_message = 'OK'
        //                 // this.alert_snackbar = true
        //             }
        //         })
        //         .catch((error) => {
        //             if (error.response.status === 401) {
        //                 this.alert_message = 'Se ha producido un error al dar de alta el usuario.'
        //                 this.alert_snackbar = true
        //             }
        //         })
        //     } else {
        //         this.alert_message = 'No somos adivinos, debes rellenar todos los campos'
        //         this.alert_snackbar = true
        //     }
        // },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section h1 {
    font-weight: 700;
    font-size: min(2.8rem, 9vw);
}
.section h2 {
    font-weight: 300;
    font-size: min(1.7rem, 6vw);
    /* position: relative; */
}

.feature-card-title {
    /* color: #02589e; */
    font-weight: 800;
    font-size: 25px;
}
.feature-card-price {
    color: #02589e;
    font-weight: 300;
    font-size: 30px;
}
.feature-card-text {
    color: #666;
    font-weight: 300;
    font-size: 15px;
}

</style>
